import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ShopPackage, ShopProduct } from '../../../../../shop-api-client';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectCartSlice } from '../../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch } from '../../../../redux/store';
import { addCartItem } from '../../../../redux/thunks/cart.thunks';
import CheckMarkSuccess from '../../../../shared/components/CheckMarkSuccess';
import DrawerSection from '../../../../shared/components/DrawerSection';
import { ADDED } from '../../../../shared/constants';
import { Params } from '../../../../shared/types/router';
import { formatCurrency } from '../../../../shared/utils';
import useConfigurationRouter from '../../Configuration/useConfigurationRouter';
import {
  isConfigurable,
  requiresPerImageBG,
  requiresPerProductBG,
  shapeEditPackage,
  shapeEditProduct,
} from '../../Configuration/utils';
import { CUSTOMIZE_AND_BUY } from '../../constants';
import ProductImage from '../../ProductImage';
import { getFirstAssignedImage } from '../../utils';

interface Props {
  heading: string;
  onClose(): void;
  shopitems: (ShopPackage | ShopProduct)[];
}

const OtherItems = ({ heading, onClose, shopitems }: Props) => {
  const { justAdded } = useSelector(selectCartSlice);
  const { currency } = useSelector(selectAccount);
  const gallery = useSelector(selectGallery);
  const { backgroundSets, imageOptionMap, preOrderBackgroundSelectionType, productNodeMap } =
    useSelector(selectPriceSheet);

  const justAddedMap = useMemo(
    () =>
      justAdded.other.reduce<Record<string, boolean>>((res, added) => {
        res[added.priceSheetItemID] = true;
        return res;
      }, {}),
    [justAdded.other],
  );

  const { routeToConfigurationPath } = useConfigurationRouter();
  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isGreenScreen, isPreOrder } = gallery;

  const handleAddSuggestedItem = async (item: ShopProduct | ShopPackage) => {
    const hasImageOptions = !!Object.keys(imageOptionMap).length && item.type !== 'nonPrintProduct';
    const needsConfiguration = isConfigurable(isPreOrder, item, productNodeMap);

    const canEditPerProductBG = requiresPerProductBG(
      item.type,
      preOrderBackgroundSelectionType,
      isGreenScreen,
      isPreOrder,
      backgroundSets,
    );
    const canEditPerImageBG = requiresPerImageBG(
      item.type,
      preOrderBackgroundSelectionType,
      isGreenScreen,
      isPreOrder,
      backgroundSets,
    );
    const canEditBG = canEditPerProductBG || canEditPerImageBG;

    // If nothing is editable for the selected item, add to cart:
    if (!hasImageOptions && !needsConfiguration && !canEditBG) {
      let editItem;
      if (item.type === 'package' || item.type === 'package-byop') {
        editItem = shapeEditPackage(item, productNodeMap);
      } else {
        editItem = shapeEditProduct(item, productNodeMap);
      }

      await dispatch(addCartItem(editItem));
      return;
    }

    onClose();

    window.gtag('event', 'view_add_to_cart_item', {
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_category: item.type,
          price: item.price,
        },
      ],
    });

    if (canEditBG && !hasImageOptions && !needsConfiguration) {
      // Only requires BG selection, so route to details:
      history.push(`/${key}/shop/${item.categoryID}/product/${item.id}`);
    } else {
      routeToConfigurationPath(key, item, gallery);
    }
  };

  const renderButtonOrStatus = (item: ShopPackage | ShopProduct) => {
    if (justAddedMap[item.id]) {
      return <CheckMarkSuccess text={ADDED} />;
    }

    return (
      <Button
        color="brand"
        fontSize="sm"
        height="30px"
        justifyContent="flex-start"
        onClick={() => handleAddSuggestedItem(item)}
        padding={0}
        variant="secondary"
        width="182px"
      >
        {`+ ${CUSTOMIZE_AND_BUY}`}
      </Button>
    );
  };

  return (
    <DrawerSection minHeight="200px" paddingBottom={20} paddingX={8}>
      <Heading fontSize="lg" marginY={3}>
        {heading}
      </Heading>
      <Flex direction="column">
        {shopitems.map(product => {
          return (
            <Box key={product.id} marginY={4} width="100%">
              <Flex justify="start">
                <Box height="70px" paddingTop={1.5} width="70px">
                  <ProductImage
                    fallbackFontSize="5px"
                    fallbackIconSize="sm"
                    image={getFirstAssignedImage(product)}
                    product={product}
                    width={70}
                  />
                </Box>
                <Flex direction="column" marginLeft={3} width="260px">
                  <Heading fontSize="md" marginY={1}>
                    {product.name}
                  </Heading>
                  <Text data-test={`product-price-${product.name}`} fontSize="sm">
                    {formatCurrency(product.price, currency)}
                  </Text>
                  {renderButtonOrStatus(product)}
                </Flex>
              </Flex>
            </Box>
          );
        })}
      </Flex>
    </DrawerSection>
  );
};

export default OtherItems;
