// TODO: Make GalleryWelcome fully responsive for desktop when designs are available

import { Flex, Heading, useBreakpointValue, VStack } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectInteractions } from '../../../redux/selectors/interactions.selectors';
import { toggleShowWelcomeModal } from '../../../redux/slices/interactions.slice';
import { useAppDispatch } from '../../../redux/store';
import Logo from '../../../shared/components/Logo';
import Modal from '../../../shared/components/Modal';

const GalleryWelcome = () => {
  const { company, logoImage } = useSelector(selectAccount);
  const { showWelcomeModal } = useSelector(selectInteractions);
  const { galleryConfig } = useSelector(selectGallery);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const welcomeMessage = galleryConfig?.welcomeMessage || '';

  const startShopping = intl.formatMessage({
    id: 'galleryWelcome.startShopping',
    defaultMessage: 'Start Shopping',
  });
  const welcomeHeading = intl.formatMessage({
    id: 'galleryWelcome.welcome',
    defaultMessage: 'Welcome!',
  });
  const welcomeImageAlt = intl.formatMessage({
    id: 'galleryWelcome.welcomeImageAlt',
    defaultMessage: 'Welcome Image',
  });

  const handleOnClose = () => dispatch(toggleShowWelcomeModal(false));

  return (
    <Modal
      actionLabel={startShopping}
      footerStyles={{ paddingBottom: 10 }}
      hideCancel
      isOpen={showWelcomeModal}
      maxWidth="500px"
      onClose={handleOnClose}
      onConfirm={handleOnClose}
      scrollBehavior={isMobile ? 'outside' : 'inside'}
    >
      <VStack alignItems="start" spacing={5} height="100%">
        <Logo
          alt={welcomeImageAlt}
          fallbackMargin={8}
          fallbackText={company}
          imageSrc={galleryConfig?.welcomeImage || logoImage}
          margin="auto"
          maxHeight="240px"
          maxWidth={['200px', '300px']}
        />
        <Heading fontSize="xl" data-test="welcome-heading-text">
          {welcomeHeading}
        </Heading>
        <Flex
          alignItems="center"
          dangerouslySetInnerHTML={{ __html: welcomeMessage }}
          flexDirection="column"
          fontSize="md"
          grow={1}
          paddingBottom={7}
          data-test="gallery-welcome-message-text"
        />
      </VStack>
    </Modal>
  );
};
export default GalleryWelcome;
