import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopBuildYourOwnPackage } from '../../../../../../../shop-api-client';
import {
  selectAddedUnits,
  selectConfiguration,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { NEXT_CUSTOMIZE, UNIT, UNITS, unit, units } from '../../../constants';
import useConfigurationRouter from '../../../useConfigurationRouter';
import PackageBYOPEditor from '../../EditorPreview/PackageBYOPEditor';
import EditorSidebar from '../../EditorSidebar';
import SidebarBYOPItems from '../../EditorSidebar/SidebarBYOPItems';
import PackageFooter from '../../PackageFooter';

// TODO: The desired behavior for displaying like-items remains to be implemented. Currently the "All Items" list
//    will display each unique BYOP availableProduct. Perhaps we will de-dupe items across included/available items
//    that are exactly the same, depending on the desired user experience.
//    Upon doing so, we must consider some "gotchas":
// 1. If the price/unit value is different between non-included items, they must be kept separate, however currently
//    we ignore price in our `customizer`, because we want the "X included" flag to appear on an available item (whose
//    price is > 0) that is the same as an included item (whose price IS 0)
// 2. Regardless of making the aforementioned change, we must consider that when incrementing a quantity of an item in
//    this view, we are not actually incrementing quantity, but rather we are adding a new cart/edit sub item from
//    the incremented item. This is potentially problematic if the visitor then configures each item differently, but
//    subsequently returns to this step and REMOVES an item. In this case, we must add some intermediary modal or step
//    to have the visitor confirm which item must be removed

const BuildPackage = () => {
  const { editPackage } = useSelector(selectConfiguration);
  const { products } = useSelector(selectPriceSheet);
  const addedUnits = useSelector(selectAddedUnits);

  // State
  const [showModal, setShowModal] = useState(false);

  // Misc hooks
  const { goToDetails, routeToWizardStep } = useConfigurationRouter();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const shopPackage = products[editPackage!.priceSheetItemID] as ShopBuildYourOwnPackage;
  const balance = shopPackage.includedUnits - addedUnits;
  const isMinimumMet = addedUnits >= shopPackage.minUnits;

  if (!editPackage || !shopPackage || shopPackage.type !== 'package-byop') {
    return null;
  }
  const handleBack = () => goToDetails(shopPackage.categoryID);
  const handleClose = () => setShowModal(false);

  const handleContinueToCustomization = () => {
    // UX Note: On desktop, we want to route the visitor to the first configurable item. However,
    // for mobile, we initialize on the "CustomizeOverview" layout:
    const firstItem = !isMobile ? 1 : undefined;
    routeToWizardStep('customize', firstItem);
  };

  const handleNext = () => {
    if (!isMinimumMet || balance > 0) {
      // The modal will show a message to the user if they have units left or they haven't met
      // the minimum requirements
      setShowModal(true);
    } else {
      handleContinueToCustomization();
    }
  };

  const renderModalHeading = () => {
    // TODO: Get the minimum not met verbage from Chris
    if (isMinimumMet) {
      return intl.formatMessage(
        {
          id: 'buildPackage.remainingUnits',
          defaultMessage: '{count} {unit} Remaining',
        },
        {
          count: balance,
          unit: balance === 1 ? UNIT : UNITS,
        },
      );
    }

    return intl.formatMessage({
      id: 'buildPackage.minUnitsNotMetHeader',
      defaultMessage: 'Minimum Requirements Not Met',
    });
  };

  const renderModalWarningMsg = () => {
    // TODO: Get the minimum not met verbage from Chris
    if (isMinimumMet) {
      return intl.formatMessage(
        {
          id: 'buildPackage.unusedUnits',
          defaultMessage:
            "You haven't used all of your available units yet. You still have {count} more {unit} worth of items",
        },
        {
          count: balance,
          unit: balance === 1 ? unit : units,
        },
      );
    }

    return intl.formatMessage(
      {
        id: 'buildPackage.minUnitsNotMet',
        defaultMessage: 'You need at least {minUnitsNeeded} more {unit} before proceeding',
      },
      {
        minUnitsNeeded: shopPackage.minUnits - addedUnits,
        unit: shopPackage.minUnits === 1 ? UNIT : UNITS,
      },
    );
  };

  const renderModalBtn = () => {
    // TODO: Get the minimum not met verbage from Chris
    // this modal will now allow customers to continue on to customization in the following cases:
    // * When the max number of units has been reached
    // * If there's a minimum and it has been met or exceeded even if there are available units
    const btnText = isMinimumMet
      ? intl.formatMessage({
          id: 'buildPackage.continueCustomize',
          defaultMessage: 'Continue to Customize',
        })
      : intl.formatMessage({
          id: 'buildPackage.continueBuilding',
          defaultMessage: 'Continue Building',
        });
    const clickHandler = isMinimumMet
      ? () => {
          handleClose();
          handleContinueToCustomization();
        }
      : handleClose;
    return (
      <Button marginBottom={1} onClick={clickHandler} width={{ base: '100%', md: '400px' }}>
        {btnText}
      </Button>
    );
  };

  const renderModal = () => {
    if (!showModal) {
      return null;
    }

    return (
      <Modal
        blockScrollOnMount
        isCentered={false}
        isOpen
        onClose={handleClose}
        size={['full', 'xl']}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius={{ base: 0, md: 6 }}
          paddingBottom={{ base: 0, md: 6 }}
          paddingTop={{ base: 0, md: 12 }}
        >
          <Flex direction="column" align="center" textAlign="center" width="100%">
            <ModalHeader fontFamily="ITC Avant Garde Gothic Demi" fontSize="4xl">
              !
            </ModalHeader>
            <Text fontFamily="ITC Avant Garde Gothic Demi" fontSize="2xl">
              {renderModalHeading()}
            </Text>
          </Flex>
          <ModalCloseButton borderRadius="50%" />
          <ModalBody
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            paddingBottom={12}
            paddingTop={3}
          >
            <Text data-test="modal-warning-message" color="red.6" align="center" size="md">
              {renderModalWarningMsg()}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex align="center" direction="column" width="100%">
              {renderModalBtn()}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  if (isMobile) {
    return (
      <>
        <Flex direction="column">
          <SidebarBYOPItems />
          <Flex direction="column" paddingX="20px" paddingBottom="64px">
            <PackageBYOPEditor />
          </Flex>
        </Flex>
        <PackageFooter onBack={handleBack} onNext={handleNext} nextLabel={NEXT_CUSTOMIZE} />
        {renderModal()}
      </>
    );
  }

  return (
    <>
      <Flex overflow="hidden" direction="row">
        <Box overflowY="auto" marginX="auto" paddingX="20px">
          <PackageBYOPEditor />
        </Box>
        <EditorSidebar>
          <SidebarBYOPItems />
        </EditorSidebar>
      </Flex>
      <PackageFooter onBack={handleBack} onNext={handleNext} nextLabel={NEXT_CUSTOMIZE} />
      {renderModal()}
    </>
  );
};

export default BuildPackage;
