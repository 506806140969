import { Button, Divider, Flex } from '@chakra-ui/react';
import React from 'react';

interface Props {
  buttonMargin?: string | number;
  label: string;
  labelSize?: string | number;
}

/**
 * This component renders two horizontal dividers separated by a label
 */
const DividerWithLabel = ({ label, labelSize = 'xs' }: Props) => (
  <Flex align="center" width="100%">
    <Divider borderColor="grey.3" marginY={3} />
    <Button
      data-test="show-label"
      color="brand"
      fontSize={labelSize}
      lineHeight="1.8"
      variant="link"
      width="100%"
    >
      {label}
    </Button>
    <Divider borderColor="grey.3" marginY={3} />
  </Flex>
);

export default DividerWithLabel;
