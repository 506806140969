import { Global } from '@emotion/react';
import React from 'react';

const fonts = `
  @font-face {
    font-family: 'ITC Avant Garde Gothic Book Oblique';
    src: url('../../assets/fonts/itc-avant-garde-gothic-book-oblique.woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'ITC Avant Garde Gothic Book';
    font-weight: 'thin';
    src: url('../../assets/fonts/itc-avant-garde-gothic-book.woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'ITC Avant Garde Gothic Demi Oblique';
    font-weight: 'bold';
    src: url('../../assets/fonts/itc-avant-garde-gothic-demi-oblique.woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'ITC Avant Garde Gothic Demi';
    src: url('../../assets/fonts/itc-avant-garde-gothic-demi.woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  @font-face {
    font-family: 'Inter';
    font-weight: 'thin';
    src: url('../../assets/fonts/inter-variable.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`;

const GlobalFonts = () => <Global styles={fonts} />;
export default GlobalFonts;
