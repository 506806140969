import { AxiosError } from 'axios';
import { ImageNode } from 'iq-product-render';
import moment from 'moment';
import * as momenttz from 'moment-timezone';
import pRetry from 'p-retry';
import en_locale from '../../compiled-lang/en.json';
import fr_locale from '../../compiled-lang/fr.json';
import {
  GalleryType,
  ShopImage,
  ShopPackage,
  ShopProduct,
  ShopProductCollection,
  ShopProductPrint,
} from '../../shop-api-client';
import {
  CartImageNodeReq,
  CreateCartPackageReq,
  VisitWithCart,
} from '../../shop-api-client/models/Cart';
import { ShopProductNodes } from '../../shop-api-client/models/ShopProductNodes';
import { FORBIDDEN, intl } from './constants';
import { UniqueImage } from './types/image';

export const generateRandomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min) + min);

export const loadLocaleMessages = (locale: string) => {
  switch (locale) {
    case 'fr':
    case 'fr-CA':
      return fr_locale;
    case 'en':
      return en_locale;
    default:
      return en_locale;
  }
};

/**
 * Rotate items of an array, wrapping around itself
 */
export const rotateArray = <T>(arr: T[], rotateCount: number) => {
  const length = arr.length;
  const result = [...arr];
  result.push(...result.splice(0, ((-rotateCount % length) + length) % length));
  return result;
};

// -------------------------------------------------------------------------
// Date
// -------------------------------------------------------------------------

export const convertToLocalTimeZone = (date: Date | string | undefined | null, format?: string) => {
  if (!date) {
    return;
  }
  const localDate = getLocalDate(date);
  return !format ? localDate.toString() : localDate.format(format);
};

export const getLocalDate = (date: string | Date = new Date()) => {
  const timezone = momenttz.tz.guess();
  return moment(date).tz(timezone);
};

export const formatDate = (date: string | Date, format = 'MMMM Do, YYYY') => {
  if (!date) {
    return;
  }

  return moment(date).format(format);
};

export const formatDateTime = (date: string | Date) => {
  if (!date) {
    return;
  }

  return moment(date).format('MM/DD/YYYY h:mm a');
};

// -------------------------------------------------------------------------
// Currency
// -------------------------------------------------------------------------

export const formatCurrencyToParts = (number: number, currency: string | null) => {
  if (isNaN(number)) {
    return {
      currencySymbol: '',
      currencyCode: '',
      integer: number,
      decimal: '',
      fraction: '',
      literal: undefined,
    };
  }

  const currencyCode = currency || 'USD';
  const parts = intl.formatNumberToParts(number, {
    currency: currencyCode,
    style: 'currency',
  });

  const partMap = parts.reduce<Partial<Record<Intl.NumberFormatPartTypes, string>>>(
    (acc, { type, value }) => {
      if (acc.integer && (type === 'integer' || type === 'group')) {
        acc['integer'] += value;
      } else {
        acc[type] = value;
      }
      return acc;
    },
    {},
  );

  const currencySymbol = partMap.currency?.replace(/[a-z]/gi, '');
  const hasCurrencyCode = !!partMap.currency?.replace(/[^a-z]/gi, '');
  return { ...partMap, currencySymbol, currencyCode: hasCurrencyCode ? currencyCode : '' };
};

export const formatCurrency = (number: number, currency: string | null) => {
  if (isNaN(number)) {
    return number;
  }
  const { currencyCode, currencySymbol, integer, decimal, fraction, literal } =
    formatCurrencyToParts(number, currency);

  const signPrefix = number < 0 ? '-' : '';
  const codeSuffix = currencyCode ? ' ' + currencyCode : '';

  if (typeof literal === 'string') {
    return `${signPrefix}${integer}${decimal}${fraction} ${currencySymbol}${codeSuffix}`;
  }
  return `${signPrefix}${currencySymbol}${integer}${decimal}${fraction}${codeSuffix}`;
};

// -------------------------------------------------------------------------
// Images
// -------------------------------------------------------------------------

export const getAspectRatio = (height: number, width: number) => {
  const isPortrait = height > width;
  return isPortrait ? height / width : width / height;
};

export const getScaledHeight = (aspectRatio: number, targetW: number) => targetW / aspectRatio;
export const getScaledWidth = (aspectRatio: number, targetH: number) => targetH * aspectRatio;

export const getUniqueImageKey = (internalName: string, backgroundID?: number) =>
  `${internalName}-${backgroundID}`;

/**
 * @returns the unique images used across a cart package's products, as long as the image requirement
 * type is not "group" within a subject gallery
 */
export const getUniqueImages = (
  cartPackage: CreateCartPackageReq,
  shopPackage: ShopPackage,
  images: Record<string, ShopImage>,
  galleryType: GalleryType,
  productNodeMap: ShopProductNodes,
): UniqueImage[] => {
  const uniqueImages = new Set<string>();

  const stringifyImageData = (image: string, backgroundID: number | null | undefined) => {
    // NOTE: fallback to null to ensure consistency, if the passed value is null or undefined:
    return JSON.stringify({
      image,
      backgroundID: backgroundID || null,
    });
  };

  /**
   * Finds whether a pose from a no-background image node is used in other sub-items so that it
   * will be skipped from being counted as a unique pose, and avoids cost being incurred
   */
  const skipNoBackgroundPose = (node: CartImageNodeReq, skipBackground?: boolean) => {
    const { imageInternalName, backgroundID } = node;
    let shouldSkip = false;

    // Cart nodes that require a background when applicable should not be skipped for unique poses
    if (!skipBackground) {
      return false;
    }

    for (const product of cartPackage.products) {
      if (shouldSkip) {
        break;
      }

      if (product.type === 'collection' || product.type === 'imageDownload') {
        shouldSkip = product.collectionImages.some(
          i => i.internalName === imageInternalName && backgroundID,
        );
      } else if (product.type === 'product') {
        shouldSkip = product.nodes.some(
          n => n.type === 'image' && n.imageInternalName === imageInternalName && n.backgroundID,
        );
      }
    }
    return shouldSkip;
  };

  const pkgItemMap = shopPackage.availableProducts.reduce<Record<string, ShopProduct>>(
    (map, item) => {
      map[item.id] = item;
      return map;
    },
    {},
  );

  for (const product of cartPackage.products) {
    const shopProduct = pkgItemMap[product.priceSheetItemID];
    if (product.type === 'collection' || product.type === 'imageDownload') {
      for (const image of product.collectionImages || []) {
        if (
          !image.internalName ||
          (galleryType === 'subject' &&
            (shopProduct as ShopProductCollection).imageRequirementType === 'group')
        ) {
          continue;
        }

        uniqueImages.add(stringifyImageData(image.internalName, image.backgroundID));
      }
    } else if (product.type === 'product') {
      for (const node of product.nodes || []) {
        const imageRequirement = (shopProduct as ShopProductPrint).imageRequirementProperties.find(
          req => req.nodeID === node.catalogNodeID,
        );
        const catalogNode = productNodeMap[shopProduct.catalogProductID].find(
          n => n.id === node.catalogNodeID,
        );

        if (
          node.type !== 'image' ||
          !node.imageInternalName ||
          (galleryType === 'subject' && imageRequirement?.type === 'group') ||
          skipNoBackgroundPose(node, (catalogNode as ImageNode)?.skipBackgroundSelection)
        ) {
          continue;
        }

        uniqueImages.add(stringifyImageData(node.imageInternalName, node.backgroundID));
      }
    }
  }

  return Array.from(uniqueImages).map(i => JSON.parse(i));
};

// Image cache for anything that goes through the getImageBuffer function
const IMAGE_CACHE: Record<string, Promise<string | ArrayBuffer>> = {};

export const getImageBuffer = async (imgSrc: string | null, retries = 3) => {
  if (!imgSrc) {
    return '';
  }

  // Serve from cache first
  if (imgSrc in IMAGE_CACHE) {
    return IMAGE_CACHE[imgSrc];
  }

  // Define the load function that gets put into the cache
  const load = async () => {
    let imgData: Response | undefined = undefined;
    try {
      imgData = await pRetry(() => fetch(imgSrc, { cache: 'no-cache' }), { retries });
    } catch (e) {
      return '';
    }
    const imgBlob = await imgData.blob();

    const buffer = await new Promise<string | ArrayBuffer | null>(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(imgBlob);
      reader.onloadend = () => {
        const base64Data = reader.result;
        resolve(base64Data);
      };
    });

    return buffer || '';
  };

  IMAGE_CACHE[imgSrc] = load();
  return IMAGE_CACHE[imgSrc];
};

/**
 * @deprecated The values per image are applied in shop-api so this function
 * should not be used.
 *
 * Waiting on QA before we remove it entirely
 */
export const getImageDimensions = (image: ShopImage) => ({
  height: (image.crop.height / 100) * image.height,
  width: (image.crop.width / 100) * image.width,
});

export const getImageWidthByRatio = (isPortrait: boolean, height: number, ratio: number) =>
  isPortrait ? height / ratio : height * ratio;

interface Pluralize {
  (count: number, singularUnit: string, includeCount: boolean): string;
  (count: number, singularUnit: string, pluralUnit: string, includeCount?: boolean): string;
  (count: number, singularUnit: string, pluralUnit?: string): string;
}

// -------------------------------------------------------------------------
// Text
// -------------------------------------------------------------------------

export const getNameWithoutExt = (filename: string = '') =>
  filename.substring(0, filename.lastIndexOf('.')) || filename;

// TODO: This function was taken from Blueprint's utils, so move the shared function to iq-shared-utils
/**
 * Pluralizes the given word (`singularUnit`), based on `count`
 */
export const pluralize: Pluralize = (
  count: number,
  singularUnit: string,
  ...args: (boolean | string | undefined)[]
) => {
  const includeCount = (args.find(arg => typeof arg === 'boolean') as boolean) || false;
  const pluralUnit = (args.find(arg => typeof arg === 'string') as string) || `${singularUnit}s`;

  const unit = count === 1 ? singularUnit : pluralUnit;

  return includeCount ? `${count} ${unit}` : unit;
};

export const roundCurrency = (number: number) => {
  if (Number.isFinite(number)) {
    return Math.round(number * 100) / 100;
  }
  return 0;
};

export const sort = (a: string | number | null, b: string | number | null) => {
  if (a === b) {
    return 0;
  } else if (a === null) {
    return 1;
  } else if (b === null) {
    return -1;
  }
  return a < b ? -1 : 1;
};

// -------------------------------------------------------------------------
// Error handling
// -------------------------------------------------------------------------

export const isForbiddenError = (error: any) =>
  error?.isAxiosError && (error as AxiosError).response?.status === FORBIDDEN;

// -------------------------------------------------------------------------
// Routing
// -------------------------------------------------------------------------

/**
 * Send visitor back to a vetted initial path or designated landing
 * if multigallery, ALWAYS send to path
 */
export const getSecurePath = (
  visitKey: string,
  pathname: string,
  isPreOrder: boolean,
  isStandardGallery: boolean,
  isMultiGallery?: boolean,
) => {
  const directedPath = isPreOrder
    ? `/${visitKey}/shop/all`
    : `/${visitKey}/photos${isStandardGallery ? '' : '/all'}`;

  if (isMultiGallery) {
    return directedPath;
  }
  const securedPaths = new RegExp(
    `/${visitKey}/(photos|shop|carts|checkout|yearbook|configure)/?.*`,
  );
  const isSecurePath = securedPaths.test(pathname);

  return isSecurePath ? pathname : directedPath;
};

/**
 * @returns `/${key}/shop/all`
 */
export const getShopAllPath = (key: string) => `/${key}/shop/all`;

// -------------------------------------------------------------------------
// Google Analytics
// -------------------------------------------------------------------------

export interface GAItem {
  item_id: number;
  item_name: string;
  item_category?: string;
  affiliation?: string;
  price?: number;
  quantity?: number;
}

export const addGaItem = (item: GAItem, gaItems: GAItem[]) => {
  const gaItem: any = gaItems.find(
    (i: GAItem) =>
      i.item_id === item.item_id &&
      i.item_category === item.item_category &&
      i.item_name === item.item_name &&
      i.price === item.price,
  );
  if (gaItem) {
    gaItem.quantity += item.quantity;
  } else {
    gaItems.push(item);
  }
};

export const getGAItems = (cartMap: Record<string, VisitWithCart>, accountID: string) => {
  const gaItems: GAItem[] = [];
  for (const cart of Object.values(cartMap)) {
    for (const cartProduct of cart.cartProducts) {
      addGaItem(
        {
          item_id: cartProduct.priceSheetItemID,
          item_name: cartProduct.name,
          item_category: cartProduct.type,
          affiliation: accountID,
          price: cartProduct.unitPrice,
          quantity: cartProduct.quantity,
        },
        gaItems,
      );
    }
    for (const imageOption of cart.cartImageOptions) {
      addGaItem(
        {
          item_id: imageOption.priceSheetOptionID,
          item_name: imageOption.name,
          item_category: imageOption.type,
          affiliation: accountID,
          price: imageOption.unitPrice,
          quantity: imageOption.quantity,
        },
        gaItems,
      );
    }
    for (const cartPackage of cart.cartPackages) {
      addGaItem(
        {
          item_id: cartPackage.priceSheetItemID,
          item_name: cartPackage.name,
          item_category: cartPackage.type,
          affiliation: accountID,
          price: cartPackage.unitPrice,
          quantity: cartPackage.quantity,
        },
        gaItems,
      );
      for (const packageProduct of cartPackage.products) {
        addGaItem(
          {
            item_id: packageProduct.priceSheetItemID,
            item_name: packageProduct.name,
            item_category: packageProduct.type,
            affiliation: accountID,
            price: packageProduct.unitPrice,
            quantity: packageProduct.quantity,
          },
          gaItems,
        );
      }
    }
  }
  return gaItems;
};
