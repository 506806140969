const colors = {
  // Color schemes
  blue: {
    1: '#EDF6FB',
    2: '#A3CAEE',
    3: '#7AB5E4',
    4: '#52A2D9',
    5: '#298FCD',
    6: '#017DC0',
    7: '#044AA5',
    8: '#06248B',
    9: '#070872',
    10: '#180759',
  },
  green: {
    1: '#D0EACC',
    2: '#ACD8A3',
    3: '#89C57A',
    4: '#68B152',
    5: '#499C29',
    6: '#2A8703',
    7: '#0E7405',
    8: '#066212',
    9: '#065020',
    10: '#063F26',
  },
  grey: {
    1: '#f3f4f5',
    2: '#d8d9da',
    3: '#bebfc0',
    4: '#a4a5a6',
    5: '#8c8c8d',
    6: '#737475',
    7: '#5c5d5e',
    8: '#464748',
    9: '#313232',
    10: '#1d1e1f',
  },
  red: {
    1: '#F1CDCC',
    2: '#E5A5A3',
    3: '#D77C7A',
    4: '#C85352',
    5: '#B92929',
    6: '#A80000',
    7: '#8C2003',
    8: '#723304',
    9: '#583A05',
    10: '#3F3605',
  },
  // IQ DEFAULT BRAND COLORS
  IQBLUE: '#00A6DC',
  IQHIGHLIGHT: '#00A6DC70',
  // CLIENT BRAND COLORS
  brand: '#017DC0', // TODO SC-61: replace this value with "brand" color
  brandLight: '#017DC033',
  deepCyan: '#017DC0',
  error: '#A80000',
  focus: '#007EA8',
  highlight: '#1a88d6a3',
  primaryAlertBackground: '#E8F0FE',
  primaryAlertBorder: '#DDE5F2',
  primaryAlertText: '#1967D2',
  semiTransparentBlack: '#00000057',
  semiTransparentWhite: '#ffffffd9',
  successGreen: '#2A8703',
  // TODO: Define a standard for tinting color palette values
  tintedGreenOne: '#d0eacc33', // green.1 + opacity .20

  // Text Input
  textPrimary: '#1A202C',
  textLabel: '#58595A',
};

export default colors;
