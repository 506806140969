import { Button, Checkbox, Collapse, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplyDiscount } from '../../../../../shop-api-client/models/Discounts';
import { selectCheckoutSlice } from '../../../../redux/selectors/checkout.selectors';
import FloatingLabelInput from '../../../../shared/components/FloatingLabelInput';
import { apply, remove } from '../../../../shared/constants/discounts.constants';

export interface PromoCodeProps {
  appliedDiscount: ApplyDiscount;
  error: ApplyDiscount | undefined;
  handleApplyDiscount(discount: ApplyDiscount): void;
  handleRemoveDiscount(): void;
}

/**
 * Collapsible input that applies a provided discount code
 */
const PromoCode = ({
  appliedDiscount,
  error,
  handleApplyDiscount,
  handleRemoveDiscount,
}: PromoCodeProps) => {
  const { isSubmitting } = useSelector(selectCheckoutSlice);

  const [code, setCode] = useState(appliedDiscount.discountCode || '');
  const [showInput, setShowInput] = useState(false);

  const intl = useIntl();

  const promoCodePrompt = intl.formatMessage({
    id: 'promoCode.promoCodePrompt',
    defaultMessage: 'Do you have a discount or promo code?',
  });
  const promoCodePlaceHolder = intl.formatMessage({
    id: 'promoCode.promoCodePlaceholder',
    defaultMessage: 'Promo Code',
  });
  const onlyOnePerOrder = intl.formatMessage({
    id: 'promoCode.onlyOnePerOrder',
    defaultMessage:
      'You may only use 1 code per order. If a code was auto-applied, you can use the remove button and enter your own code.',
  });
  const checkboxAriaLabel = intl.formatMessage({
    id: 'promoCode.togglePromoCodeInput',
    defaultMessage: 'Toggle Promo Code Input',
  });
  const invalidPromoCode = intl.formatMessage({
    id: 'promoCode.invalidPromoCode',
    defaultMessage: 'The code you entered is invalid or has expired.',
  });

  const showError = error?.discountCode === code;

  useEffect(() => {
    if (appliedDiscount.discountCode) {
      setCode(appliedDiscount.discountCode);
      setShowInput(true);
    }
    // discount code is removed manually or by applying another
    if (!appliedDiscount.discountCode) {
      setCode('');
    }
    // if used quick apply, also hide promo
    if (appliedDiscount.discountID) {
      setShowInput(false);
    }
  }, [appliedDiscount.discountCode, appliedDiscount.discountID]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    handleApplyDiscount({ discountCode: code });
  };

  const handlePromoCode = () => {
    if (appliedDiscount.discountCode) {
      handleRemoveDiscount();
    } else {
      handleApplyDiscount({ discountCode: code });
    }
  };

  const handleCheckbox = () => {
    setShowInput(!showInput);
  };

  return (
    <Flex direction="column">
      <Checkbox
        data-test="promo-code-prompt"
        aria-label={checkboxAriaLabel}
        isChecked={showInput}
        marginBottom={5}
        marginTop={3}
        onChange={handleCheckbox}
        isDisabled={isSubmitting}
      >
        {promoCodePrompt}
      </Checkbox>

      <Collapse in={showInput}>
        <Flex data-test="promo-code-flex" marginBottom={2}>
          <FloatingLabelInput
            autoComplete="off"
            data-test="promo-code-input"
            invalidMessage={invalidPromoCode}
            isDisabled={!!appliedDiscount.discountCode || isSubmitting}
            isInvalid={showError}
            marginRight="15px"
            name="Promo Code"
            onChange={handleChange}
            onKeyUp={handleEnter}
            inputLabel={promoCodePlaceHolder}
            value={code}
            width="350px"
          />

          <Button
            disabled={(!code && !appliedDiscount.discountCode) || isSubmitting}
            padding="20px"
            type="submit"
            variant={appliedDiscount.discountCode ? 'white' : 'black'}
            marginTop={2}
            onClick={handlePromoCode}
          >
            {appliedDiscount.discountCode ? remove : apply}
          </Button>
        </Flex>

        <Text fontSize="11px" fontStyle="italic" maxWidth="350px">
          {onlyOnePerOrder}
        </Text>
      </Collapse>
    </Flex>
  );
};

export default PromoCode;
