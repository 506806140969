import { Box, Button, Flex, Heading, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ShopBackground, ShopBackgroundSet, ShopImage } from '../../../../../../../shop-api-client';
import { selectAccount } from '../../../../../../redux/selectors/account.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import Modal from '../../../../../../shared/components/Modal';
import ThumbnailWithBackground from '../../../../../../shared/components/ThumbnailWithBackground';
import { formatCurrency } from '../../../../../../shared/utils';
import {
  CHOOSE_BACKGROUND_HEADING,
  CHOOSE_BACKGROUND_SUBHEADING,
  THUMB_GRID_MAX_HEIGHT,
  THUMB_GRID_MAX_WIDTH,
  SHOW,
  HIDE,
} from '../../../constants';

interface Props {
  columns?: number;
  error?: boolean;
  image: ShopImage | null;
  imageMaxHeight?: string;
  imageMaxWidth?: string;
  selectBackground(image: ShopImage, background: ShopBackground): void;
  selectedBackgrounds?: number[];
  showAsHorizontalList?: boolean;
  showBackgroundName?: boolean;
}

/** displays a GS image with all possible backgrounds
 * on selection, executes a function passed in from parent */
const ImageNodeBackgroundDisplay = ({
  columns = 4,
  error = false,
  image,
  imageMaxHeight = THUMB_GRID_MAX_HEIGHT,
  imageMaxWidth = THUMB_GRID_MAX_WIDTH,
  selectBackground,
  selectedBackgrounds = [],
  showAsHorizontalList,
  showBackgroundName = true,
}: Props) => {
  // Selectors
  const { backgroundSets } = useAppSelector(selectPriceSheet);
  const { currency } = useAppSelector(selectAccount);

  // State
  const [expanded, setExpanded] = useState(false);
  const [hidePose, setHidePose] = useState(false);

  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });
  const intl = useIntl();

  const showOrHidePoses = intl.formatMessage(
    {
      id: 'imageNodeBgDisplay.showOrHidePoses',
      defaultMessage: '{showOrHide} Poses',
    },
    { showOrHide: hidePose ? SHOW : HIDE },
  );
  const allBackgrounds = intl.formatMessage({
    id: 'imageNodeBgDisplay.allBackgrounds',
    defaultMessage: 'All Backgrounds',
  });
  const expand = intl.formatMessage({
    id: 'imageNodeBgDisplay.expand',
    defaultMessage: 'Expand',
  });

  if (!image || !image.isGreenScreen) {
    return null;
  }

  const handleSelectImage = (image: ShopImage, background: ShopBackground) => {
    // Wrap the selectBackground props function to facilitate closing the expanded view
    if (expanded) {
      setExpanded(false);
    }

    selectBackground(image, background);
  };

  const renderBackgrounds = (backgrounds: ShopBackground[]) => {
    return backgrounds.map(bg => {
      const isSelected = selectedBackgrounds.some(s => s === bg.id);
      let borderColor = error ? 'error' : 'transparent';

      if (isSelected) {
        borderColor = 'brand';
      }

      const borderRadius = isMobile ? '3px' : '5px';
      const borderWidth = isMobile ? '3px' : '4px';
      const borderPadding = isMobile ? '2px' : '4px';

      let maxHeight: string | number | undefined = imageMaxHeight;
      let maxWidth: string | number | undefined = imageMaxWidth;
      if (showAsHorizontalList && !expanded) {
        maxHeight = '100px';
        maxWidth = '80px';
      }

      return (
        <Flex
          key={bg.id}
          direction="column"
          alignItems="center"
          marginBottom={showAsHorizontalList ? 3 : 2}
          justifyContent="space-between"
          flex={1}
        >
          <Box
            data-test={`background-swatches-${bg.name}`}
            borderColor={borderColor}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            padding={borderPadding}
          >
            <ThumbnailWithBackground
              background={bg.sources.thumb}
              containerOnClick={() => handleSelectImage(image, bg)}
              isActive={isSelected}
              maxHeight={maxHeight}
              maxWidth={maxWidth}
              opacity={hidePose ? '0' : '100'}
              src={image.sources.thumb}
            />
          </Box>
          {showBackgroundName && (
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              maxWidth={maxWidth}
              noOfLines={1}
              textAlign="center"
            >
              {bg.name}
            </Text>
          )}
        </Flex>
      );
    });
  };

  const renderHeader = () => {
    if (!showAsHorizontalList) {
      return null;
    }

    const backgroundCount = backgroundSets.reduce(
      (sum, backgroundSet) => sum + backgroundSet.backgrounds.length,
      0,
    );

    return (
      <Flex direction="row" width="100%" paddingX={2} paddingTop={2}>
        <Text fontFamily="heading" fontSize="14px">
          {allBackgrounds} ({backgroundCount})
        </Text>
        <Button
          color="brand"
          margin={0}
          marginLeft="auto"
          onClick={() => setExpanded(!expanded)}
          variant="link"
        >
          {expand}
        </Button>
      </Flex>
    );
  };

  const renderBGSet = (index: number, { id, name, backgrounds, price }: ShopBackgroundSet) => {
    return (
      <Box
        key={id}
        marginBottom={showAsHorizontalList ? 3 : 2}
        marginTop={showAsHorizontalList ? 1 : 2}
      >
        {(!showAsHorizontalList || expanded) && (
          <Flex alignItems="center">
            <Heading data-test={`${name}-price`} size="xs">
              {`${name} (${backgrounds.length}) ${price ? formatCurrency(price, currency) : ''}`}{' '}
            </Heading>
            {index === 0 && (
              <Button
                data-test="poses-button"
                color="brand"
                marginLeft="auto"
                minWidth="90px"
                onClick={() => setHidePose(!hidePose)}
                variant="link"
              >
                {showOrHidePoses}
              </Button>
            )}
          </Flex>
        )}
        {showAsHorizontalList && !expanded ? (
          <Flex direction="row">{renderBackgrounds(backgrounds)}</Flex>
        ) : (
          <SimpleGrid
            columns={{ base: 2, md: columns }}
            justifyItems={expanded ? 'center' : undefined}
            marginTop={4}
            overflowY="auto"
            spacing={2}
          >
            {renderBackgrounds(backgrounds)}
          </SimpleGrid>
        )}
      </Box>
    );
  };

  if (expanded) {
    // The expanded view is shown as a modal
    return (
      <Modal
        heading={CHOOSE_BACKGROUND_HEADING}
        isOpen
        onClose={() => setExpanded(false)}
        scrollBehavior="outside"
        size="full"
        subHeading={CHOOSE_BACKGROUND_SUBHEADING}
      >
        {backgroundSets.map((set, index) => renderBGSet(index, set))}
      </Modal>
    );
  }

  return (
    <>
      {renderHeader()}
      <Flex
        direction={showAsHorizontalList ? 'row' : 'column'}
        height="100%"
        width="100%"
        overflow={isMobile ? 'auto' : undefined}
      >
        {backgroundSets.map((set, index) => renderBGSet(index, set))}
      </Flex>
    </>
  );
};

export default ImageNodeBackgroundDisplay;
