import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { GalleryLandingData } from '../../../../shop-api-client';
import { selectVisitor } from '../../../redux/selectors/visitor.selectors';
import { toggleShowFindGalleryModal } from '../../../redux/slices/interactions.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { login } from '../../../redux/thunks/auth.thunks';
import ArrowLeftbutton from '../../../shared/components/ArrowLeftButton';
import Checkbox from '../../../shared/components/Checkbox';
import FloatingLabelInput from '../../../shared/components/FloatingLabelInput';
import Logo from '../../../shared/components/Logo';
import { LOGIN } from '../../../shared/constants';
import { HIDE_SCROLLBAR_STYLES } from '../../../shared/constants/style.constants';
import useSearchParams from '../../../shared/hooks/useSearchParams';
import { LocationWithState } from '../../../shared/types/router';
import { getSecurePath } from '../../../shared/utils';
import CopyrightText from '../../Footer/CopyrightText';
import { LoginProps } from '../Login';

const FORM_MAX_WIDTH = 484;

interface Props extends LoginProps {
  handleBack?(): void;
  isMultiGalleryView?: boolean;
  landingData: GalleryLandingData;
  /** url login key, such as a subject/gallery code used to auth/login
   * not a visitKey which can have a '-'
   */
  galleryKey: string;
}

interface LoginErrors {
  email?: string;
  galleryPassword?: string;
  onlineCode?: string;
  unableToLogin?: string;
}

interface APIValidationError {
  field: string;
  message: string;
  type: string;
}

const LoginForm = ({
  galleryKey,
  handleBack,
  isMultiGalleryView,
  landingData,
  setUnavailableMessage,
  unavailableMessage,
}: Props) => {
  // use saved redux email if logging in from multigallery
  const { email: isMultiGalleryEmail } = useAppSelector(selectVisitor);

  const [canNotify, setCanNotify] = useState(true);
  // if multigallery, email already set in redux, otherwise we need to collect it
  const [email, setEmail] = useState(isMultiGalleryEmail || '');
  const [errors, setErrors] = useState<LoginErrors>({});
  const [galleryPassword, setGalleryPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [onlineCode, setOnlineCode] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  const { getParamsValue } = useSearchParams();
  const { search, state, pathname } = useLocation<LocationWithState>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  const mid = getParamsValue('mid');
  const {
    baseGallery,
    account,
    galleryConfig: { welcomeImage, themeColor },
  } = landingData;
  const { expiredMessage, isPreOrder, type, title, hasGalleryPassword } = baseGallery;
  const requiresOnlineCode = !isPreOrder && type === 'subject' && galleryKey.startsWith('g');
  const isMultiGalleryLoginPage = pathname.includes('/galleries/') && pathname.includes('/login');

  // If gallery is expired or unavailable, deny access
  const isLocked = !!expiredMessage || !!unavailableMessage;

  const backToLoginText = intl.formatMessage({
    id: 'login.backToLogin',
    defaultMessage: 'Back to Login',
  });
  const notificationLabel = intl.formatMessage({
    id: 'login.notify',
    defaultMessage: 'Email me notifications about my gallery',
  });
  const enterOnlineCode = intl.formatMessage({
    id: 'login.enterOnlineCode',
    defaultMessage: 'Please enter an online code',
  });
  const incorrectOnlineCode = intl.formatMessage({
    id: 'login.incorrectOnlineCode',
    defaultMessage: 'The online code you entered is incorrect',
  });
  const onlineCodeLabel = intl.formatMessage({
    id: 'login.onlineCodeLabel',
    defaultMessage: 'Enter your online code below',
  });
  const onlineCodePlaceholder = intl.formatMessage({
    id: 'login.placeholderOnlineCode',
    defaultMessage: 'Online Code',
  });
  const enterPassword = intl.formatMessage({
    id: 'login.enterGalleryPassword',
    defaultMessage: 'Please enter a gallery password',
  });
  const incorrectPassword = intl.formatMessage({
    id: 'login.incorrectGalleryPassword',
    defaultMessage: 'The gallery password you entered is incorrect',
  });
  const passwordPlaceholder = intl.formatMessage({
    id: 'login.placeholderGalleryPassword',
    defaultMessage: 'Gallery Password',
  });
  const multiGalleryPasswordPlaceholder = intl.formatMessage({
    id: 'multiGallery.placeholderPassword',
    defaultMessage: 'Password',
  });
  const emailPlaceholder = intl.formatMessage({
    id: 'login.placeholderEmail',
    defaultMessage: 'Email Address',
  });
  const enterEmail = intl.formatMessage({
    id: 'login.enterEmail',
    defaultMessage: 'Please enter an email address',
  });
  const invalidEmail = intl.formatMessage({
    id: 'login.invalidEmail',
    defaultMessage: 'Please enter a valid Email Address',
  });
  const signInHeading = intl.formatMessage({
    id: 'login.signInHeading',
    defaultMessage: 'Sign In',
  });
  const unableToLogin = intl.formatMessage({
    id: 'login.unableToLogin',
    defaultMessage: 'Unable to Login',
  });

  const validationErrorMsgLookup = {
    email: invalidEmail,
    galleryPassword: incorrectPassword,
    onlineCode: incorrectOnlineCode,
    unableToLogin,
  };

  // Remove red border error state whenever new input is received
  useEffect(() => {
    setShowErrors(false);
  }, [onlineCode, galleryPassword, email]);

  // First check for input errors
  const checkInputErrors = useCallback(() => {
    const errors: LoginErrors = {};

    if (requiresOnlineCode && !onlineCode) {
      errors.onlineCode = enterOnlineCode;
    }
    if (hasGalleryPassword && !galleryPassword) {
      errors.galleryPassword = enterPassword;
    }
    if (!email) {
      errors.email = enterEmail;
    }

    setErrors(errors);

    return Object.keys(errors).length;
  }, [
    email,
    enterEmail,
    enterOnlineCode,
    enterPassword,
    galleryPassword,
    hasGalleryPassword,
    onlineCode,
    requiresOnlineCode,
  ]);

  // On enter, check if any required fields need a value, if all passes, login
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || checkInputErrors()) {
      return;
    }

    handleLogin();
  };

  // main login handler
  const handleLogin = async () => {
    // 1. check if all necessary fields have values
    if (checkInputErrors()) {
      setShowErrors(true);
      return;
    }
    setIsSubmitting(true);

    const { valid, payload, error } = await dispatch(
      login(galleryKey, {
        galleryPassword: galleryPassword.trim(),
        onlineCode: onlineCode.trim(),
        canNotify,
        email,
        mid,
      }),
    );

    //Google Analytics login event
    if (valid || payload) {
      window.gtag('event', 'login', {
        method: 'email',
      });
    }

    if (!valid || !payload) {
      setIsSubmitting(false);

      // check to see what type of error it is
      // if it's a validation error, it's an object
      if (error.response) {
        if (error.response.data?.errors) {
          const validationErrors = error.response.data.errors as APIValidationError[];

          const errorMap = validationErrors.reduce<LoginErrors>((res, e) => {
            if (['email', 'onlineCode', 'galleryPassword'].includes(e.field)) {
              const key = e.field as keyof LoginErrors;
              res[key] = validationErrorMsgLookup[key];
            }
            return res;
          }, {});
          setErrors(errorMap);
        } else {
          setErrors({ unableToLogin });
        }
        setShowErrors(true);
      }
      // Otherwise it broke elsewhere, such as loading galleries, session check, etc.
      //TODO: toast if it broke on auth/loading gallery/session data errors
      return;
    }

    const { visitKey, sessionGallery } = payload;

    // if valid login, but unavailable, lock
    if (sessionGallery?.unavailableMessage) {
      setUnavailableMessage(sessionGallery.unavailableMessage);
      return;
    }

    if (isMultiGalleryView) {
      dispatch(toggleShowFindGalleryModal(false));
    }

    document.title = title;

    const pathname = getSecurePath(
      visitKey,
      state?.prevPath,
      isPreOrder,
      type === 'standard',
      isMultiGalleryView,
    );

    history.replace({ pathname, search });
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim());

  const handleOnlineCode = (e: React.ChangeEvent<HTMLInputElement>) =>
    setOnlineCode(e.target.value);

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    setGalleryPassword(e.target.value);

  const handleToggleNotify = () => setCanNotify(!canNotify);

  const handleBackAction = () => {
    if (!handleBack) {
      return;
    }
    handleBack();
  };

  const renderOnlineCodeInput = () => (
    <>
      <Text fontSize="16px" marginY={4}>
        {onlineCodeLabel}
      </Text>
      <FloatingLabelInput
        autoComplete="off"
        data-test="login-online-code"
        invalidMessage={errors.onlineCode}
        isInvalid={showErrors && !!errors.onlineCode}
        isRequired
        name="Online Code"
        onChange={handleOnlineCode}
        inputLabel={onlineCodePlaceholder}
        value={onlineCode}
      />
    </>
  );

  const renderPasswordInput = () => (
    <FloatingLabelInput
      autoComplete="off"
      data-test="login-gallery-password"
      invalidMessage={errors.galleryPassword}
      isInvalid={showErrors && !!errors.galleryPassword}
      isRequired
      marginTop="2"
      name="Gallery Password"
      onChange={handlePassword}
      inputLabel={isMultiGalleryView ? multiGalleryPasswordPlaceholder : passwordPlaceholder}
      value={galleryPassword}
    />
  );

  const renderEmailInput = () => (
    <FloatingLabelInput
      autoComplete="email"
      data-test="login-email-address"
      invalidMessage={errors.email || errors.unableToLogin}
      isInvalid={showErrors && (!!errors.email || !!errors.unableToLogin)}
      isRequired
      marginTop="2"
      name="Email Address"
      onChange={handleEmail}
      inputLabel={emailPlaceholder}
      value={email}
    />
  );

  const renderLoginForm = () => (
    <>
      <Box onKeyDown={handleEnter}>
        {requiresOnlineCode && renderOnlineCodeInput()}
        {hasGalleryPassword && renderPasswordInput()}
        {!isMultiGalleryView && renderEmailInput()}
      </Box>
      <Box marginY={5}>
        <Checkbox marginLeft="5px" defaultChecked={canNotify} onChange={handleToggleNotify}>
          {notificationLabel}
        </Checkbox>
      </Box>
      <Flex>
        <Button
          backgroundColor={themeColor}
          data-test="login-submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          loadingText={LOGIN}
          marginLeft="auto"
          marginTop={isMultiGalleryView ? [0.25, 5] : [10, '60px']}
          minWidth={isMultiGalleryView ? 160 : ''}
          onClick={handleLogin}
          width={isMultiGalleryView ? {} : { base: '100%', sm: '166px' }}
          _hover={{
            _disabled: {
              backgroundColor: themeColor, // prevents background transparency on hover of disabled button
            },
          }}
        >
          {LOGIN}
        </Button>
      </Flex>
    </>
  );

  //if gallery is expired, render expired message
  // if gallery is unavailable, renderloginform and then once authed, display unavailable message

  // if subject on an available gallery has expired
  // and accessing subject gallery from gUrl with Code
  //expired message is returned as "unavailableMessage" from api
  const renderLockedMessage = () => (
    <Box>
      <Text marginY="40px" color="error" data-test="login-unavailable-message">
        {expiredMessage || unavailableMessage}
      </Text>
      {unavailableMessage && (
        <Flex onClick={handleBackAction} alignItems="center" width="fit-content">
          <ArrowLeftbutton
            aria-label={backToLoginText}
            border="none"
            fontSize="md"
            lightMode
            size="sm"
          />
          <Button data-test="back-to-login" marginLeft="1" variant="link">
            {backToLoginText}
          </Button>
        </Flex>
      )}
    </Box>
  );

  return (
    <Flex
      borderColor={isMultiGalleryView ? '' : 'grey.3'}
      borderRightWidth={isMultiGalleryView ? '' : [0, '2px']}
      flexDirection="column"
      height={isMultiGalleryView ? undefined : '100vh'}
      paddingTop={{
        base: isMultiGalleryView ? '10px' : '20px',
        md: isMultiGalleryView ? '10px' : '40px',
      }}
      paddingX={{ base: '20px', md: '40px' }}
      width={['100%', `${FORM_MAX_WIDTH}px`]}
    >
      <Box
        css={HIDE_SCROLLBAR_STYLES}
        flex="1"
        height="100px"
        overflow="scroll"
        paddingBottom={isMultiGalleryView ? 8 : 5}
        width="100%"
      >
        {!isMultiGalleryView && (
          <Logo
            alt="Welcome Image"
            fallbackMargin={8}
            fallbackText={account.company}
            imageSrc={account.logoImage || welcomeImage}
            margin="auto"
            marginY={[2, 6, 10]}
            maxHeight="240px"
            maxWidth={['200px', '300px']}
          />
        )}
        <Heading
          fontSize={isMultiGalleryView ? '16px' : undefined}
          marginBottom={isMultiGalleryView ? '25px' : undefined}
          size="md"
          textAlign={isMultiGalleryView && !isMultiGalleryLoginPage ? 'center' : undefined}
        >
          {signInHeading}
        </Heading>
        <Heading
          fontSize={isMultiGalleryView ? '18px' : '24px'}
          marginBottom={isMultiGalleryView ? 5 : 6}
          marginTop={isMultiGalleryView ? 3.5 : 6}
          textAlign={isMultiGalleryView && !isMultiGalleryLoginPage ? 'center' : 'left'}
          maxWidth={isMultiGalleryLoginPage ? ['325px', 'inherit'] : undefined}
        >
          {title}
        </Heading>
        {isLocked ? renderLockedMessage() : renderLoginForm()}
      </Box>
      {!isMultiGalleryView && <CopyrightText company={account.company} />}
    </Flex>
  );
};

export default LoginForm;
