import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/layout';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopBackground } from '../../../../shop-api-client';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectInteractions } from '../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch } from '../../../redux/store';
import { loadBackgroundImage } from '../../../redux/thunks/interactions.thunks';
import BackgroundSwatches from '../../../shared/components/BackgroundSwatches';
import { FREE } from '../../../shared/constants';
import { HIDE_SCROLLBAR_STYLES } from '../../../shared/constants/style.constants';
import { formatCurrency } from '../../../shared/utils';

const BackgroundViewer = () => {
  const { backgroundSets } = useSelector(selectPriceSheet);
  const { currency } = useSelector(selectAccount);
  const { selectedBackground } = useSelector(selectInteractions);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const handleSelectBackground = useCallback(
    (bg: ShopBackground) => {
      dispatch(loadBackgroundImage(bg));
    },
    [dispatch],
  );

  const renderBackgroundSets = () =>
    backgroundSets.map(set => (
      <Box key={set.id} marginTop="20px">
        <Flex marginX="5px">
          <Text fontSize="sm">{set.name}</Text>
          <Spacer />
          <Text data-test="price-per-pose" fontSize="xs">
            {set.price === 0 ? FREE : `${formatCurrency(set.price, currency)}/pose`}
          </Text>
        </Flex>
        <BackgroundSwatches
          backgrounds={set.backgrounds}
          onSelectBackground={handleSelectBackground}
          selectedBackground={selectedBackground}
        />
      </Box>
    ));

  return (
    <Flex
      alignSelf="stretch"
      borderLeftColor="grey.2"
      borderLeftStyle="solid"
      borderLeftWidth="1px"
      css={HIDE_SCROLLBAR_STYLES}
      direction="column"
      height="100vh"
      minWidth="327px"
      overflowY="scroll"
      padding="18px"
      position="sticky"
      top="0px"
      width="327px"
    >
      <Heading marginX="5px" size="sm">
        {intl.formatMessage({
          id: 'backgroundViewer.chooseBackground',
          defaultMessage: 'Choose background to preview',
        })}
      </Heading>
      {renderBackgroundSets()}
    </Flex>
  );
};

export default BackgroundViewer;
