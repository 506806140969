import { Flex, Heading, Input, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OptionForSelectionOption, TextProductOption } from '../../../../../shop-api-client';
import { getTextOptionMaxLength } from '../../../../features/Products/Configuration/utils';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectRendererProduct } from '../../../../redux/selectors/catalog.selectors';
import { useAppSelector } from '../../../../redux/store';
import { FREE, PLEASE_FILL_OUT_THIS_FIELD } from '../../../constants';
import { NO_THANKS, YES_ADD } from '../../../constants/labels.constants';
import { formatCurrency } from '../../../utils';
import SaveButton from '../../SaveButton';
import ChoiceContainer from '../ChoiceContainer';

interface Props {
  catalogProductID?: number;
  defaultText?: string;
  focused?: boolean;
  hideSave?: boolean;
  isInvalid?: boolean;
  onSelect(selection: OptionForSelectionOption | null, text?: string): void;
  option: TextProductOption;
  selectionID?: number | false;
}

const TextOption = ({
  catalogProductID,
  defaultText = '',
  focused,
  onSelect,
  hideSave,
  isInvalid,
  option,
  selectionID,
}: Props) => {
  const { currency } = useAppSelector(selectAccount);
  const rendererProduct = useAppSelector(state => selectRendererProduct(state, catalogProductID));

  const isRequired = option.requirementType === 'required';
  // string value for text input
  const [optionText, setOptionText] = useState(defaultText);
  // initialize with isRequired since all required prod text options require text
  // or if a selectionID was passed in, it means its an optional text option
  // that has been intially opted into
  // further handling of the optional toggles is handled here internally
  // since optional saving is saved onblur and isn't reliable until actual text is entered
  const [showTextInput, setShowTextInput] = useState(isRequired || !!selectionID);

  // self-manages invalid state
  const [error, setError] = useState(false);

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const maxLength = getTextOptionMaxLength(option, rendererProduct || null);

  const intl = useIntl();

  //react making us jump hoops to focus an input
  useEffect(() => {
    if (!isRequired || focused) {
      inputRef?.focus();
    }
  }, [inputRef, isRequired, focused]);

  useEffect(() => {
    if (isInvalid) {
      setError(true);
    }
  }, [isInvalid]);

  const characterCount = intl.formatMessage(
    {
      id: 'option.characterCount',
      defaultMessage: '{usedCount} of {maxLength}',
    },
    { usedCount: optionText.length, maxLength },
  );

  /**
   * Saves the input value given for text option
   */
  const handleSaveText = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!optionText) {
      //if no text and its optional we assume they want out
      if (!isRequired) {
        setShowTextInput(false);
        onSelect(null);
      } else {
        //required text option
        setError(true);
      }
      return;
    }

    onSelect(option.selections[0], optionText);
  };

  /**
   * Handles text option onChange by setting the selection ID and storing the text value
   */
  const handleTextOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const { value } = e.target;
    if (maxLength && value.length > maxLength) {
      setOptionText(value.slice(0, maxLength));
      return;
    }

    setOptionText(value);
  };

  /**
   * Saves the visitor's selection for opting in/out of a optional text option
   * if they opt into an optional text product option, there is a single element
   * in the selections array which indicates the selection choice
   */
  const handleToggle = (optIn: boolean) => {
    // Toggle state to show/hide text input:
    setShowTextInput(optIn);

    // if opting in, we need the text input
    //else set selection as undefined to indicate an opt out
    if (!optIn) {
      setOptionText('');
      onSelect(null);
    }
  };

  /** for optional text options only */
  const renderToggleChoices = () => (
    <>
      <ChoiceContainer
        isSelected={showTextInput === false}
        onClick={() => handleToggle(false)}
        paddingRight={1}
        data-test="toggle-option-no"
      >
        <Heading fontSize="md" textAlign="center">
          {NO_THANKS}
        </Heading>
      </ChoiceContainer>
      <ChoiceContainer
        data-test="toggle-option-yes"
        isSelected={showTextInput}
        onClick={() => handleToggle(true)}
        paddingLeft={1}
      >
        <Heading fontSize="md" textAlign="center">
          {YES_ADD}
        </Heading>
        <Text data-test="Toggle Option Yes-price" fontSize="md" textAlign="center">
          {option.price ? formatCurrency(option.price, currency) : FREE}
        </Text>
      </ChoiceContainer>
    </>
  );

  return (
    <>
      {!isRequired && renderToggleChoices()}
      {(isRequired || showTextInput) && (
        <Flex direction="column" marginTop={2} width="100%">
          <Input
            ref={ref => setInputRef(ref)}
            autoFocus={focused}
            isRequired={isRequired}
            isInvalid={error}
            onBlur={!isRequired || hideSave ? handleSaveText : undefined}
            onChange={handleTextOptionChange}
            marginRight={2}
            name="Text Option"
            paddingY={1}
            value={optionText}
            data-test="option-text-box"
          />
          <Flex>
            {error && (
              <Text
                data-test="required-to-fill-error"
                color="error"
                fontSize="xs"
                paddingLeft={1}
                paddingTop={2}
                textAlign="left"
                width="100%"
              >
                {PLEASE_FILL_OUT_THIS_FIELD}
              </Text>
            )}
            {!!maxLength && (
              <Text color="grey.5" fontSize="xs" paddingRight={1} textAlign="right" width="100%">
                {characterCount}
              </Text>
            )}
          </Flex>
          {!hideSave && isRequired && <SaveButton onSave={handleSaveText} />}
        </Flex>
      )}
    </>
  );
};

export default TextOption;
