import { Z_INDEX_ONE } from '../../shared/constants';

const Select = {
  variants: {
    primary: {
      field: {
        borderColor: 'grey.3',
        borderRadius: '8px',
        borderWidth: 2,
        fontFamily: 'ITC Avant Garde Gothic Book',
        fontSize: 'xs',
      },
    },
    standard: {
      field: {
        borderColor: 'grey.3',
        borderWidth: 2,
        borderRadius: '8px',
        fontSize: '16px',
        height: '44px',
        marginTop: '0px',
        _invalid: { borderColor: 'error' },
        _focus: {
          borderColor: 'focus',
          _placeholder: {
            color: 'transparent',
          },
        },
        _required: {
          color: 'red',
        },
      },
      icon: {
        paddingTop: '10px',
      },
    },
    floating: {
      field: {
        background: 'transparent',
        borderColor: 'grey.3',
        borderWidth: '2px',
        borderRadius: '8px',
        fontSize: '16px',
        height: '55px',
        paddingLeft: '13px',
        paddingTop: '10px',
        marginTop: '0px',
        zIndex: Z_INDEX_ONE,
        _invalid: { borderColor: 'error' },
        _focus: {
          borderColor: 'focus',
          _placeholder: {
            color: 'transparent',
          },
        },
        _required: {
          color: 'red',
        },
      },
      icon: {
        paddingTop: '10px',
      },
    },

    custom: (props: any) => ({
      bg: props.customPrimary,
    }),
  },
};
export default Select;
