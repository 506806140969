import { Box, Flex, Text } from '@chakra-ui/react';
import { AddressElement } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import countriesWithoutPostcodes from 'countries-without-postcodes';
import { getData as getCountries } from 'country-list';
import * as EmailValidator from 'email-validator';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { debounce } from 'lodash';
import provinces from 'provinces';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Address, api } from '../../../../shop-api-client';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectCheckoutSlice } from '../../../redux/selectors/checkout.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { setCheckoutAddress } from '../../../redux/slices/checkout.slice';
import { useAppDispatch } from '../../../redux/store';
import { getCheckoutFinancials } from '../../../redux/thunks/checkout.thunks';
import FloatingLabelInput from '../../../shared/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../shared/components/FloatingLabelSelect';
import { Params } from '../../../shared/types/router';
import { sort } from '../../../shared/utils';
import { CheckoutStep } from '../CheckoutData';
import {
  ADDRESS_FIELDS,
  ADDRESS_MAX_CHARS,
  ARMED_FORCES_PROVINCES,
  AUTOCOMPLETE,
  CITY_MAX_CHARS,
  EMAIL_MAX_CHARS,
  INPUT_LABELS,
  NAME_MAX_CHARS,
  PHONE_MAX_CHARS,
  ZIP_MAX_CHARS,
} from '../constants';
import { getInvalidMessage, getTooLongMessage } from '../utils';
import CheckoutAddressComplete from './CheckoutAddressComplete';

const countries = getCountries().sort((a, b) => sort(a.name, b.name));

interface ProvinceOption {
  label: string;
  value: string;
}

const getProvinces = (country: CountryCode) => {
  const additional = country === 'US' ? ARMED_FORCES_PROVINCES : [];
  return [...provinces, ...additional]
    .reduce<ProvinceOption[]>((res, p) => {
      if (p.country === country) {
        res.push({
          value: p.short || p.name,
          label: p.name || p.short!,
        });
      }
      return res;
    }, [])
    .sort((a, b) => sort(a.label, b.label));
};

interface AddressStep extends CheckoutStep {
  hasTaxState?: boolean;
  hideErrorState?: boolean;
  showEmail?: boolean;
  showPhone?: boolean;
  stepKey: 'billingAddress' | 'shippingAddress';
}

const CheckoutAddressForm = ({
  hasTaxState,
  hideErrorState,
  isActive,
  isComplete,
  setValidateCallback,
  showEmail = true,
  showPhone = true,
  stepKey,
}: AddressStep) => {
  const { isSubmitting, formData: checkoutData } = useSelector(selectCheckoutSlice);
  const { currency } = useSelector(selectAccount);
  const currentVisitKey = useSelector(selectCurrentVisitKey);

  const [address, setAddress] = useState<Address>(checkoutData[stepKey]);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [errorState, setErrorState] = useState(false);

  const { checkoutID } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const provinces = useMemo(() => getProvinces(address.country as CountryCode), [address.country]);

  const accessDisclaimer = intl.formatMessage({
    id: 'checkout.addressNotification',
    defaultMessage: 'Used for order notifications & digital delivery of photos.',
  });

  const isUSA = address.country === 'US';
  const showZip = !address.country || !countriesWithoutPostcodes[address.country];
  const stateLabel = isUSA ? INPUT_LABELS.state : INPUT_LABELS.province;
  const zipCodeLabel = isUSA ? INPUT_LABELS.zip : INPUT_LABELS.postalCode;

  useEffect(() => {
    if (!address.country) {
      const getCountry = () => {
        if (currency === 'CAD') {
          return 'CA';
        }
        if (currency === 'AUD') {
          return 'AU';
        }
        return 'US';
      };
      setAddress({ ...address, country: getCountry() });
    }
  }, [address, currency]);

  // Validate callback:
  // Validate data + check for errors
  // Send API/Redux updates when next button is clicked
  const validate = useCallback(async () => {
    if (Object.keys(errors).length) {
      setErrorState(true);
      return false;
    }

    dispatch(setCheckoutAddress({ stepKey, data: address }));
    const result = await api.updateCheckout(currentVisitKey!, checkoutID!, {
      ...checkoutData,
      [stepKey]: address,
    });
    return !!result;
  }, [checkoutID, checkoutData, currentVisitKey, errors, address, dispatch, stepKey]);

  // Set the Callback as a callable function in parent state when isActive
  useEffect(() => {
    if (!isActive) {
      return;
    }
    setValidateCallback({ validate });
  }, [setValidateCallback, validate, isActive]);

  useEffect(() => {
    // Create lookup of errors for each field based on type/requiredness
    const errors = ADDRESS_FIELDS.reduce<Record<string, string>>((errMap, field) => {
      const { subjectMap } = field;
      const { validate } = EmailValidator;
      const fieldLabel = INPUT_LABELS[subjectMap];

      // Potential errors if address fields dont pass validation
      if (subjectMap === 'firstName' || subjectMap === 'lastName') {
        if (address[subjectMap].length > NAME_MAX_CHARS) {
          errMap[subjectMap] = getTooLongMessage(fieldLabel);
        } else if (!address[subjectMap]) {
          errMap[subjectMap] = getInvalidMessage(fieldLabel);
        }
      }
      if (subjectMap === 'address1' || subjectMap === 'address2') {
        if (address[subjectMap] && address[subjectMap]!.length > ADDRESS_MAX_CHARS) {
          errMap[subjectMap] = getTooLongMessage(fieldLabel);
        } else if (subjectMap === 'address1' && !address[subjectMap]) {
          errMap[subjectMap] = getInvalidMessage(fieldLabel);
        }
      }
      if (subjectMap === 'country') {
        if (!address[subjectMap]) {
          errMap[subjectMap] = getInvalidMessage(fieldLabel);
        }
      }
      if (subjectMap === 'city') {
        if (address[subjectMap].length > CITY_MAX_CHARS) {
          errMap[subjectMap] = getTooLongMessage(fieldLabel);
        } else if (!address[subjectMap]) {
          errMap[subjectMap] = getInvalidMessage(fieldLabel);
        }
      }
      if (provinces.length && subjectMap === 'state') {
        if (!address[subjectMap]) {
          errMap[subjectMap] = getInvalidMessage(stateLabel);
        }
      }
      // Only error for invalid zip code field if the country has a Zip/Postal code:
      if (subjectMap === 'zip' && showZip) {
        if (address[subjectMap].length > ZIP_MAX_CHARS) {
          errMap[subjectMap] = getTooLongMessage(zipCodeLabel);
        } else if (!address[subjectMap]) {
          errMap[subjectMap] = getInvalidMessage(zipCodeLabel);
        }
      }
      if (subjectMap === 'phone' && showPhone) {
        if (address[subjectMap].length > PHONE_MAX_CHARS) {
          errMap[subjectMap] = getTooLongMessage(fieldLabel);
        } else if (!isValidPhoneNumber(address[subjectMap], address.country as CountryCode)) {
          errMap[subjectMap] = getInvalidMessage(fieldLabel);
        }
      }
      if (subjectMap === 'email' && showEmail) {
        if (address[subjectMap].length > EMAIL_MAX_CHARS) {
          errMap[subjectMap] = getTooLongMessage(fieldLabel);
        } else if (!validate(address[subjectMap])) {
          errMap[subjectMap] = getInvalidMessage(fieldLabel);
        }
      }
      return errMap;
    }, {});

    setErrors(errors);
  }, [address, isActive, provinces, showEmail, showPhone, showZip, stateLabel, zipCodeLabel]);

  /**
   * Create input props for the label input component
   * Need to add an array with placeholder values for the input fields instead of using the input label twice
   * @param label
   * @param hasPlaceholder
   */
  const createInputProps = (label: string, hasPlaceholder: boolean = false) => ({
    'aria-label': INPUT_LABELS[label],
    // autoComplete: AUTOCOMPLETE[label],
    invalidMessage: errors[label],
    isDisabled: isSubmitting,
    isInvalid: errorState && !!errors[label] && !hideErrorState,
    isRequired: true,
    marginTop: 4,
    name: label,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => handleChange(e),
    inputLabel: INPUT_LABELS[label],
    placeholder: hasPlaceholder ? INPUT_LABELS[label] : '',
    type: 'text',
  });

  const handleUpdate = debounce(async (address: Address) => {
    // API call to update checkout occurs if it changed.
    // Don't dispatch to redux until we hit the next button to prevent re-render
    await api.updateCheckout(currentVisitKey!, checkoutID!, {
      ...checkoutData,
      [stepKey]: address,
    });

    dispatch(getCheckoutFinancials(checkoutID!));
  }, 250);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const updated = { ...address };
    const { name, value } = e.target;
    if (name === 'phone') {
      // If the currently stored phone number has more characters than the incoming value
      // (user is backspacing), and if the last character of the stored number is ')',
      // set incoming value directly to avoid formatting with `AsYouType` which will force
      // the closing parenthesis to be appended to the value again
      if (
        address.phone.length > value.length &&
        address.phone.charAt(address.phone.length - 1) === ')'
      ) {
        updated.phone = value;
      } else {
        updated.phone = new AsYouType(address.country as CountryCode).input(value);
      }
    } else if (name === 'country') {
      updated.country = value;
      // When country is changed, state and zipcode should be reset:
      updated.state = '';
      updated.zip = '';
    } else {
      updated[name as keyof Address] = value;
    }

    setAddress(updated);
    // If this form is responsible for updating tax per the visitor's selected state, and if the
    // field being changed is 'state' or 'country', having reset state, bypass validation to
    // update the checkout data in redux and update the checkout data in the database:
    if ((e.target.name === 'state' || e.target.name === 'country') && hasTaxState) {
      handleUpdate(updated);
    }
  };

  const renderComplete = () => (
    <Flex flexFlow="column" paddingLeft={10} paddingBottom={8}>
      <CheckoutAddressComplete address={address} />
    </Flex>
  );

  const renderFields = () => (
    <>
      <FloatingLabelInput {...createInputProps('firstName')} value={address.firstName} />
      <FloatingLabelInput {...createInputProps('lastName')} value={address.lastName} />
      <FloatingLabelInput {...createInputProps('address1', true)} value={address.address1} />
      <FloatingLabelInput
        aria-label={INPUT_LABELS['address2']}
        autoComplete={AUTOCOMPLETE['address2']}
        invalidMessage={errors['address2']}
        isDisabled={isSubmitting}
        isInvalid={errorState && !!errors['address2'] && !hideErrorState}
        isRequired={false}
        marginTop={4}
        name="address2"
        onChange={handleChange}
        inputLabel={INPUT_LABELS['address2']}
        type="text"
        value={address.address2}
      />
      <FloatingLabelSelect
        aria-label={INPUT_LABELS.country}
        invalidMessage={errors.country}
        isDisabled={isSubmitting}
        isInvalid={errorState && !!errors.country && !hideErrorState}
        isRequired
        marginRight={4}
        marginTop={4}
        name="country"
        onChange={handleChange}
        placeholder={INPUT_LABELS.country}
        value={address.country}
        width="100%"
      >
        {countries.map(({ code, name }) => (
          <option key={`${code}-${name}`} value={code}>
            {code === 'TW' ? 'Taiwan, Republic of China' : name}
          </option>
        ))}
      </FloatingLabelSelect>
      <FloatingLabelInput {...createInputProps('city')} value={address.city} />
      <Flex flexFlow="row" width="100%">
        {provinces.length > 0 && (
          <FloatingLabelSelect
            aria-label={stateLabel}
            invalidMessage={errors.state}
            isDisabled={isSubmitting}
            isInvalid={errorState && !!errors.state && !hideErrorState}
            isRequired
            marginRight={4}
            marginTop={4}
            name="state"
            onChange={handleChange}
            placeholder={stateLabel}
            value={address.state}
            width="100%"
          >
            {provinces.map(({ label, value }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </FloatingLabelSelect>
        )}
        {showZip && (
          <FloatingLabelInput
            aria-label={zipCodeLabel}
            invalidMessage={errors.zip}
            isDisabled={isSubmitting}
            isInvalid={errorState && !!errors.zip && !hideErrorState}
            isRequired={true}
            marginTop={4}
            name="zip"
            onChange={handleChange}
            inputLabel={zipCodeLabel}
            type="text"
            value={address.zip}
            width="100%"
          />
        )}
      </Flex>
      {showPhone && <FloatingLabelInput {...createInputProps('phone')} value={address.phone} />}
      {showEmail && <FloatingLabelInput {...createInputProps('email')} value={address.email} />}
    </>
  );

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    const { firstName = '', lastName = '' } = event.value;
    const { line1, line2 = '', city, state, country, postal_code } = event.value.address;

    let changes: Partial<Address> = {};
    if (event.complete) {
      changes = {
        firstName,
        lastName,
        address1: line1,
        address2: line2 || undefined,
        city,
        state,
        country,
        zip: postal_code,
      };
    }

    const updated = { ...address, ...changes };

    // Track if the state or country has changed:
    const shouldUpdateAPI =
      hasTaxState &&
      ((updated.state && address.state && updated.state !== address.state) ||
        (updated.country && address.country && updated.country !== address.country));

    setAddress(updated);
    if (shouldUpdateAPI) {
      handleUpdate(updated);
    }
  };

  const renderStripe = () => {
    return (
      <>
        <Box zIndex={2}>
          <AddressElement
            id="stripe-shipping-address-element"
            options={{
              allowedCountries: ['US', 'CA', 'AU'],
              mode: 'shipping',
              defaultValues: {
                firstName: address.firstName,
                lastName: address.lastName,
                address: {
                  line1: address.address1,
                  line2: address.address2,
                  city: address.city,
                  state: address.state,
                  country: address.country,
                  postal_code: address.zip,
                },
              },
              display: {
                name: 'split',
              },
            }}
            onChange={handleAddressChange}
          />
        </Box>
        {showPhone && <FloatingLabelInput {...createInputProps('phone')} value={address.phone} />}
        {showEmail && <FloatingLabelInput {...createInputProps('email')} value={address.email} />}
      </>
    );
  };

  return (
    <>
      {isComplete && !isActive && renderComplete()}
      {isActive && (
        <>
          <Flex flexFlow="column">
            {stepKey === 'shippingAddress' && renderStripe()}
            {stepKey === 'billingAddress' && renderFields()}
          </Flex>
          <Text fontSize="16px" paddingLeft={3} paddingTop={3} fontFamily="Inter">
            {stepKey === 'shippingAddress' && accessDisclaimer}
          </Text>
        </>
      )}
    </>
  );
};

export default CheckoutAddressForm;
