import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Order } from 'iq-api-client';
import React, { useEffect, useMemo, useState } from 'react';
import { FaFileInvoiceDollar, FaRegFrown, FaRegMeh, FaRegSmile } from 'react-icons/fa';
import { IoLocation } from 'react-icons/io5';
import { MdLocalShipping } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { api, CartCheckoutEntity, CheckoutFormData } from '../../../../shop-api-client';
import { MOBILE_NAV_HEIGHT } from '../../../ProtectedApp/ProtectedApp';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { selectCartMap } from '../../../redux/selectors/cart.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectPriceSheetMap } from '../../../redux/selectors/priceSheet.selectors';
import { selectVisitor } from '../../../redux/selectors/visitor.selectors';
import { reset } from '../../../redux/slices/checkout.slice';
import { PriceSheet } from '../../../redux/slices/gallery.slice';
import { useAppDispatch } from '../../../redux/store';
import { getCartSummary } from '../../../redux/thunks/cart.thunks';
import { Params } from '../../../shared/types/router';
import {
  addGaItem,
  convertToLocalTimeZone,
  formatCurrency,
  GAItem,
  getSecurePath,
} from '../../../shared/utils';
import { countCartItems } from '../../Carts/utils';
import { DISCOUNTS_BANNER_HEIGHT, NAV_GRID_HEIGHT } from '../../Navigation/constants';
import ProductImage from '../../Products/ProductImage';
import OrderSummary from '../OrderSummary';
import DetailsBox from './DetailsBox';
import RemainingCarts from './RemainingCarts/RemainingCarts';

const SHOW_FALLBACK_REQUESTS_LIMIT = 5;
const SHOW_FALLBACK_TIME_LIMIT = 30;

const CheckoutConfirmation = () => {
  const { accountID, currency } = useSelector(selectAccount);
  const { currentVisitKey, email } = useSelector(selectVisitor);
  const {
    isPreOrder,
    settings: { orderThankyouMessage, pickupLabel },
    title,
    type,
  } = useSelector(selectGallery);
  const cartMap = useSelector(selectCartMap);
  const priceSheetMap = useSelector(selectPriceSheetMap);

  const [checkout, setCheckout] = useState<CartCheckoutEntity>();
  const [checkoutFormData, setCheckoutFormData] = useState<CheckoutFormData>();
  const [detailCol, setDetailCol] = useState(1);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>();
  const [CESResponse, setCESResponse] = useState<number | undefined>();

  const { key, checkoutID } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const continueShopping = intl.formatMessage({
    id: 'checkout.continueShopping',
    defaultMessage: 'Continue Shopping',
  });
  const orderSuccess = intl.formatMessage({
    id: 'checkout.orderProcessing',
    defaultMessage: 'Your order has been received and is being processed.',
  });
  const orderError = intl.formatMessage({
    id: 'checkout.orderProcessingError',
    defaultMessage: 'Something went wrong placing your order, please contact support',
  });
  const orderDate = intl.formatMessage({
    id: 'checkout.orderDate',
    defaultMessage: 'Date:',
  });
  const orderNum = intl.formatMessage({
    id: 'checkout.orderNum',
    defaultMessage: 'Order #',
  });
  const orderTotal = intl.formatMessage({
    id: 'checkout.orderTotal',
    defaultMessage: 'Total:',
  });
  const orderDetailsHeading = intl.formatMessage({
    id: 'checkout.orderDetailsHeading',
    defaultMessage: 'Order Details',
  });
  const shipToHeading = intl.formatMessage({
    id: 'checkout.shipToHeading',
    defaultMessage: 'Ship To',
  });
  const billToHeading = intl.formatMessage({
    id: 'checkout.billToHeading',
    defaultMessage: 'Bill To',
  });
  const pickupAtHeading = intl.formatMessage({
    id: 'checkout.pickupAtHeading',
    defaultMessage: 'Pickup At',
  });

  const getDisplayImageKey = (priceSheetID: string | number, itemName: string) =>
    `${priceSheetID}-${itemName}`;

  /**
   * Tracks the price sheet image by a key combination of price sheet id and price sheet item name.
   * We key it this way currently because the order endpoint does not return the price sheet item id.
   * When/if a new endpoint is created in cart-service to return this data, this should be considered.
   */
  const displayImageMap = useMemo(
    () =>
      Object.entries(priceSheetMap).reduce<Record<string, string>>((result, [key, priceSheet]) => {
        const { products } = priceSheet as PriceSheet;
        for (const product of Object.values(products)) {
          const image = product.images?.length ? product.images[0] : product.image!;
          result[getDisplayImageKey(key, product.name)] = image;
        }
        return result;
      }, {}),
    [priceSheetMap],
  );

  /**
   * Keys the price sheet ID by the gallery order id, which the order items use to backtrack
   * the originating price sheet
   */
  const galleryOrderIDToPSID = useMemo(
    () =>
      order
        ? order.galleryOrders.reduce<Record<string, number>>((res, go) => {
            res[go.id] = go.gallery.priceSheetID;
            return res;
          }, {})
        : {},
    [order],
  );

  const remainingCarts = useMemo(
    () => Object.values(cartMap).filter(cart => countCartItems(cart) > 0),
    [cartMap],
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    // Limit retries and amount of time for requests made for checkout & order
    const startTime = performance.now();
    let attempts = 0;

    const gaItems: GAItem[] = [];

    const getCheckout = async () => {
      if (!checkoutID) {
        history.push(`/${key}/carts`);
        return;
      }

      // Enforce limits
      const currentTime = performance.now();
      const timeDiff = (currentTime - startTime) / 1000;
      if (timeDiff >= SHOW_FALLBACK_TIME_LIMIT || attempts >= SHOW_FALLBACK_REQUESTS_LIMIT) {
        setLoading(false);
        return;
      }

      attempts += 1;
      const checkout = await api.getCheckout(currentVisitKey!, checkoutID).catch(() => null);
      if (!checkout) {
        history.push(`/${key}/carts`);
        return;
      }

      if (!['new', 'pending'].includes(checkout.status)) {
        // If the status of the checkout is ready, set it to state and stop loading:
        setCheckout(checkout);

        if (checkout.formData) {
          const parsedFormData: CheckoutFormData = JSON.parse(checkout.formData);
          setCheckoutFormData(parsedFormData);

          let columnCount = 1;
          if (parsedFormData.shippingAddress.firstName) {
            columnCount++;
          }

          if (parsedFormData.billingAddress.firstName) {
            columnCount++;
          }

          if (parsedFormData.shippingType === 'pickup') {
            columnCount++;
          }

          setDetailCol(columnCount);
        }

        // Let's check if the order is also available:
        if (checkout.orderID) {
          const orderData = await api.getOrder(key, checkout.orderID);
          const order = orderData.orders[0];
          setOrder(order);

          //Google Analytics
          for (const item of order.items) {
            addGaItem(
              {
                item_id: item.catalogProductID,
                item_name: item.name,
                item_category: item.type,
                affiliation: accountID,
                price: item.unitPrice,
                quantity: item.quantity,
              },
              gaItems,
            );
          }
          for (const option of order.imageOptions) {
            addGaItem(
              {
                item_id: option.id,
                item_name: option.name,
                item_category: option.type,
                affiliation: accountID,
              },
              gaItems,
            );
          }
          for (const option of order.orderOptions) {
            addGaItem(
              {
                item_id: option.catalogOptionID,
                item_name: option.catalogName,
                item_category: option.type,
                affiliation: accountID,
                price: option.unitPrice,
                quantity: option.quantity,
              },
              gaItems,
            );
          }

          // Google Analytics purchase event
          window.gtag('event', 'purchase', {
            transaction_id: order.id,
            value: order.total,
            tax: order.tax,
            shipping: order.shipping + order.handling,
            discount: order.discount,
            items: gaItems,
            currency,
          });

          // reset checkout, carts, interactions, and configuration slice
          dispatch(reset());
          // With the order now confirmed, let's grab the carts:
          await dispatch(getCartSummary());
        }

        setLoading(false);
      } else {
        timer = setTimeout(getCheckout, 3000);
      }
    };

    getCheckout();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [checkoutID, currentVisitKey, dispatch, history, key, title, accountID, currency]);

  const getNavHeight = () => {
    if (isMobile) {
      return MOBILE_NAV_HEIGHT;
    }
    return DISCOUNTS_BANNER_HEIGHT + NAV_GRID_HEIGHT;
  };

  if (loading) {
    return (
      <Center height={`calc(100vh - ${getNavHeight()}px)`}>
        <Spinner />
      </Center>
    );
  }

  if (!order || !checkout || !checkoutFormData) {
    return (
      <Flex
        height={`calc(100vh - ${getNavHeight()}px)`}
        flexDirection="column"
        margin="0 auto"
        width={{ base: '87.5%', lg: '40%' }}
      >
        <Heading size="md" marginTop="50px" marginBottom="20px">
          {intl.formatMessage({
            id: 'checkout.orderTakingTooLong',
            defaultMessage: 'Sorry, your order is taking longer than expected to finish processing',
          })}
        </Heading>
        <Text marginBottom="10px">
          {intl.formatMessage({
            id: 'checkout.cardHasBeenCharged',
            defaultMessage:
              'Your credit card has been charged and once the order has processed, you will receive an email receipt sent to the billing email address entered at checkout.',
          })}
        </Text>
        <Text>
          {intl.formatMessage({
            id: 'checkout.contactUs',
            defaultMessage:
              "Please contact us if you do not receive your receipt in 24 hours, and we'll let you know the status of your order.",
          })}
        </Text>
      </Flex>
    );
  }

  const handleContinueShoppingBtn = () => {
    history.push(getSecurePath(key, '', isPreOrder, type === 'standard'));
  };

  const renderOrderDetails = () => {
    const formattedOrderDate = convertToLocalTimeZone(order.orderDate, 'MM/DD/YYYY h:mm a z');

    return (
      <Stack
        borderColor="grey.2"
        borderRadius="md"
        borderWidth="2px"
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-around"
        marginY={8}
        spacing={0}
        width="100%"
      >
        <DetailsBox
          detailCol={detailCol}
          heading={orderDetailsHeading}
          icon={InfoIcon}
          showBorder={false}
        >
          <Text>
            {orderDate} {formattedOrderDate}
          </Text>
          <Text>
            {orderNum} {order.id}
          </Text>
          <Text>
            {orderTotal} {formatCurrency(order.total, currency)}
          </Text>
        </DetailsBox>

        {checkoutFormData.shippingAddress.firstName && (
          <DetailsBox
            detailCol={detailCol}
            heading={shipToHeading}
            icon={MdLocalShipping}
            showBorder={!isMobile}
          >
            <Text>
              {checkoutFormData.shippingAddress.firstName}{' '}
              {checkoutFormData.shippingAddress.lastName}
              <br />
              {checkoutFormData.shippingAddress.address1}{' '}
              {checkoutFormData.shippingAddress.address2}
              <br />
              {checkoutFormData.shippingAddress.city}, {checkoutFormData.shippingAddress.state}
              <br />
              {checkoutFormData.shippingAddress.zip}
            </Text>
          </DetailsBox>
        )}

        {order.shipmentType === 'pickup' && (
          <DetailsBox
            detailCol={detailCol}
            heading={pickupAtHeading}
            icon={IoLocation}
            showBorder={!isMobile}
          >
            <Text>{pickupLabel}</Text>
          </DetailsBox>
        )}

        {checkoutFormData.billingAddress.firstName && (
          <DetailsBox
            detailCol={detailCol}
            heading={billToHeading}
            icon={FaFileInvoiceDollar}
            showBorder={!isMobile}
          >
            <Text>
              {checkoutFormData.billingAddress.firstName} {checkoutFormData.billingAddress.lastName}
              <br />
              {checkoutFormData.billingAddress.address1} {checkoutFormData.billingAddress.address2}
              <br />
              {checkoutFormData.billingAddress.city}
              {checkoutFormData.billingAddress.state &&
                `, ${checkoutFormData.billingAddress.state}`}
              <br />
              {checkoutFormData.billingAddress.zip}
            </Text>
          </DetailsBox>
        )}
      </Stack>
    );
  };

  const renderStatus = () => {
    let status = orderSuccess;

    if (checkout.status === 'error') {
      status = orderError;
    }

    return (
      <>
        <Text fontSize="sm" fontWeight="bold" marginBottom={2} align="center">
          {status}
        </Text>
        <Button
          data-test="checkout-confirmation-continue-shopping-button"
          marginBottom={[1.5, 8]}
          marginTop={4}
          onClick={handleContinueShoppingBtn}
        >
          {continueShopping}
        </Button>
      </>
    );
  };

  const renderOrderSummary = () => (
    <Flex
      direction={isMobile ? 'column' : 'row'}
      height="100%"
      margin="0 auto"
      marginBottom={8}
      marginTop={{ base: 0, lg: 8 }}
      width="100%"
    >
      <Flex marginX={4} flexGrow={1} direction="column">
        <Heading size="md">
          {intl.formatMessage({
            id: 'checkout.orderedItemsHeading',
            defaultMessage: 'Ordered Items',
          })}
        </Heading>
        {order.items.map(
          ({ galleryOrderID, id, name, unitPrice, quantity, type, priceSheetMetadata }, index) => {
            const displayImageKey = getDisplayImageKey(galleryOrderIDToPSID[galleryOrderID], name);
            const isLastItem = index === order.items.length - 1;
            const partialItemData = {
              type: type === 'multiPage' ? 'product' : type,
              maxImages: priceSheetMetadata?.maxImages,
            };
            return (
              <Box key={id} marginY={5} width="100%">
                <Flex justify="start">
                  <Flex
                    borderBottomWidth={isLastItem ? undefined : '1px'}
                    borderColor={isLastItem ? undefined : 'grey.2'}
                    direction="column"
                    key={id}
                    marginY={2}
                    maxWidth="600px"
                    minHeight="100px"
                    paddingBottom={[isLastItem ? null : 4, 4]}
                    position="relative"
                    width="100%"
                  >
                    <Flex grow={1}>
                      <Box width="145px" marginTop={1}>
                        <ProductImage
                          fallbackFontSize="11px"
                          fallbackIconSize="20px"
                          image={displayImageMap[displayImageKey]}
                          product={partialItemData}
                          width={120}
                        />
                      </Box>
                      <Flex direction="column" marginLeft={4} width="100%">
                        <Flex>
                          <Box flexGrow={1}>
                            <Text fontSize="md" fontWeight="bold" lineHeight={2}>
                              {name}
                            </Text>
                            <Text fontSize="md" fontWeight="bold" lineHeight={2}>
                              {formatCurrency(unitPrice * quantity, currency)}
                            </Text>

                            <Text fontSize="md" lineHeight={2}>
                              {intl.formatMessage(
                                {
                                  id: 'checkout.itemQuantity',
                                  defaultMessage: 'QTY: {quantity}',
                                },
                                { quantity },
                              )}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            );
          },
        )}
      </Flex>
      <Flex
        alignItems="center"
        border="1px"
        borderColor="grey.2"
        borderRadius="lg"
        borderWidth="2px"
        direction="column"
        flexBasis="350px"
        justifyContent="center"
        padding={6}
      >
        <OrderSummary
          financials={{
            backgroundFees: 0,
            digitalDownloadTax: 0,
            discount: order.discount,
            handling: order.handling,
            imageOptionFees: 0,
            orderOptionFees: 0,
            shipping: order.shipping,
            subtotal: order.subtotal,
            tax: order.tax,
            total: order.total,
          }}
          isFinalCalculation
        />
      </Flex>
    </Flex>
  );

  const handleCES = async (cesValue: number) => {
    setCESResponse(cesValue);
    await api.upsertCESMetrics(key, checkoutID!, cesValue);
  };

  const renderCESMetric = () => {
    const cesMap = {
      1: {
        cesValue: 1,
        icon: FaRegFrown,
        color: '#A80000',
        text: intl.formatMessage({
          id: 'checkout.cesValue1',
          defaultMessage: 'Strongly Disagree',
        }),
      },
      2: {
        cesValue: 2,
        icon: FaRegFrown,
        color: '#D17F05',
        text: intl.formatMessage({
          id: 'checkout.cesValue2',
          defaultMessage: 'Disagree',
        }),
      },
      3: {
        cesValue: 3,
        icon: FaRegMeh,
        color: '#D9B700',
        text: intl.formatMessage({
          id: 'checkout.cesValue3',
          defaultMessage: 'Undecided',
        }),
      },
      4: {
        cesValue: 4,
        icon: FaRegSmile,
        color: '#A3AC02',
        text: intl.formatMessage({
          id: 'checkout.cesValue4',
          defaultMessage: 'Agree',
        }),
      },
      5: {
        cesValue: 5,
        icon: FaRegSmile,
        color: '#2A8703',
        text: intl.formatMessage({
          id: 'checkout.cesValue5',
          defaultMessage: 'Strongly Agree',
        }),
      },
    };

    return (
      <Flex
        margin="0 auto"
        marginBottom={2}
        marginTop={8}
        width="100%"
        borderColor="grey.2"
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
        bgColor="#F2F4F5"
        direction="column"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Text color="#1A202C" alignItems="center" fontWeight={600} fontSize="16px" marginY={2}>
          {intl.formatMessage({
            id: 'checkout.cesFeedback',
            defaultMessage: 'It was easy for me to order photos.',
          })}
        </Text>
        <Stack direction="row" spacing={0} width="100%" height="100%">
          {Object.values(cesMap).map(ces => (
            <Flex
              key={ces.cesValue}
              h="100%"
              w="100%"
              borderColor="grey.2"
              borderTopWidth="2px"
              borderBottomWidth="2px"
              borderLeftWidth={ces.cesValue === 1 ? '2px' : undefined}
              borderRightWidth="2px"
              borderRightRadius={ces.cesValue === 5 ? 'md' : undefined}
              borderLeftRadius={ces.cesValue === 1 ? 'md' : undefined}
              cursor="pointer"
              aria-label={ces.text}
            >
              <Flex
                h="100%"
                w="100%"
                alignItems="center"
                borderRightRadius={ces.cesValue === 5 ? 'md' : undefined}
                borderLeftRadius={ces.cesValue === 1 ? 'md' : undefined}
                outline={ces.cesValue === CESResponse ? '2px solid' : undefined}
                bgColor="white"
                direction="column"
                padding={2}
                justifyContent="center"
                onClick={() => handleCES(ces.cesValue)}
              >
                <Icon as={ces.icon} color={ces.color} boxSize="32px" />
                <Flex h="40px" alignItems="center">
                  <Text
                    align="center"
                    fontWeight={600}
                    lineHeight="14px"
                    fontSize="12px"
                    color="#8C8C8D"
                  >
                    {ces.text}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Stack>
        {CESResponse && (
          <Flex paddingX={4} paddingY={4} alignItems="center">
            <Text color="#2A8703" fontWeight={600} fontSize="14px" align="center">
              <Icon as={CheckCircleIcon} color="#2A8703" marginRight={2} boxSize="14px" />
              {intl.formatMessage({
                id: 'checkout.cesFeedbackReceived',
                defaultMessage:
                  'Thanks for your feedback! Your answers help improve the experience.',
              })}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Flex
      alignItems="center"
      flexFlow="column"
      height="100%"
      justifyContent="center"
      margin="0 auto"
      marginBottom={8}
      marginTop={{ base: 0, lg: 8 }}
      maxWidth={isMobile ? undefined : '1150px'}
      paddingX={4}
    >
      <Heading size="md" marginTop={6} marginBottom={4} marginX={4} textAlign="center">
        {orderThankyouMessage} {`#${order.id}`}
      </Heading>

      {remainingCarts.length ? <RemainingCarts /> : renderStatus()}

      <Text fontSize="sm" marginTop={6} marginX={4} align="center">
        {intl.formatMessage(
          {
            id: 'checkout.orderMsg',
            defaultMessage: "We've sent a confirmation email and receipt to {email}",
          },
          { email: checkoutFormData.billingAddress.email || email },
        )}
      </Text>
      {renderCESMetric()}
      {renderOrderDetails()}
      {renderOrderSummary()}
    </Flex>
  );
};

export default CheckoutConfirmation;
