import { Divider } from '@chakra-ui/react';
import React from 'react';
import Checkbox from '../../../shared/components/Checkbox';
import SidebarSection from '../../../shared/components/SidebarSection';

interface Props {
  filterableMap: { [key: string]: (string | number)[] };
  heading: string;
  intlLabelMap: { [key: string]: string };
  isMobile?: boolean;
  onChange(filter: string): void;
  productFilters: string[];
}

const ProductFilterSection = ({
  filterableMap,
  heading,
  intlLabelMap,
  isMobile,
  onChange,
  productFilters,
}: Props) => (
  <>
    <SidebarSection
      defaultCollapsed={isMobile}
      heading={heading}
      isCollapsible
      paddingLeft={{ base: '25px', md: '35px' }}
      showBorder={isMobile}
    >
      {Object.keys(filterableMap).map(filter => (
        <Checkbox
          key={filter}
          isChecked={productFilters.includes(filter)}
          onChange={() => onChange(filter)}
          data-test={`product-filter-section-checkbox-${intlLabelMap[filter]}`}
          value={intlLabelMap[filter]}
          margin="5px"
        >
          {intlLabelMap[filter]}
        </Checkbox>
      ))}
    </SidebarSection>
    {!isMobile && <Divider variant="sidebar" />}
  </>
);

export default ProductFilterSection;
