import { Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { BooleanOption, OptionForSelectionOption } from '../../../../../shop-api-client';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../../redux/store';
import { FREE } from '../../../constants';
import { NO_THANKS, YES_ADD } from '../../../constants/labels.constants';
import { formatCurrency } from '../../../utils';
import NotificationPin from '../../NotificationPin';
import ChoiceContainer from './../ChoiceContainer';

interface Props {
  isInvalid?: boolean;
  onSelect(selection: OptionForSelectionOption | null, value?: string): void;
  option: BooleanOption;
  selectionID?: number | false;
  /**only used for multi-image image option selection */
  selectionBadges?: Record<number, number>;
}

const ToggleOption = ({ isInvalid, selectionID, onSelect, option, selectionBadges }: Props) => {
  const { currency } = useAppSelector(selectAccount);

  // TODO: we currently have no way to "save" an opt out state for a toggle and even a redux state would be gone on refresh
  // our current temp fix is to highlight an opt out when selectionID is null/undefined
  // we'll be revisiting required options (product, image, order) to see how we can record opt out data for BI
  return (
    <>
      <ChoiceContainer
        isInvalid={isInvalid}
        isSelected={selectionID === false}
        onClick={() => onSelect(null)}
        paddingRight={1}
        data-test="toggle-option-no"
        position="relative"
      >
        <Heading fontSize="md" textAlign="center">
          {NO_THANKS}
        </Heading>
      </ChoiceContainer>
      <ChoiceContainer
        data-test="toggle-option-yes"
        isInvalid={isInvalid}
        isSelected={option.selections[0].id === selectionID}
        onClick={() => onSelect(option.selections[0], '1')}
        paddingLeft={1}
        position="relative"
      >
        {!!selectionBadges?.[option.selections[0].catalogOptionID] && (
          <NotificationPin
            content={selectionBadges[option.selections[0].catalogOptionID]}
            fontSize="sm"
            height="25px"
            position="absolute"
            right="-8px"
            top="-5px"
            width="25px"
          />
        )}
        <Heading fontSize="md" textAlign="center">
          {YES_ADD}
        </Heading>
        <Text data-test="Toggle Option Yes-price" fontSize="md" textAlign="center">
          {option.price ? formatCurrency(option.price, currency) : FREE}
        </Text>
      </ChoiceContainer>
    </>
  );
};

export default ToggleOption;
