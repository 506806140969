import { Divider, Flex, Spacer, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopPackage } from '../../../../../../../shop-api-client';
import { CartPackage } from '../../../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../../../redux/selectors/account.selectors';
import {
  selectConfiguration,
  selectConfigurationSummary,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch } from '../../../../../../redux/store';
import { addCartItem, updateCartItem } from '../../../../../../redux/thunks/cart.thunks';
import Swatch from '../../../../../../shared/components/BackgroundSwatches/Swatch';
import { FREE } from '../../../../../../shared/constants';
import useSearchParams from '../../../../../../shared/hooks/useSearchParams';
import { Params } from '../../../../../../shared/types/router';
import { formatCurrency, getNameWithoutExt } from '../../../../../../shared/utils';
import { getProductOptionFees } from '../../../../../Carts/utils';
import { ADD_TO_CART } from '../../../../constants';
import { ADDITIONAL_CART_ITEMS, PHOTO_ADD_ONS, TOTAL, UPDATE } from '../../../constants';
import AddOnsSummaryList from '../../../shared/AddOnsSummaryList';
import useConfigurationRouter from '../../../useConfigurationRouter';
import { getBackground } from '../../../utils';
import PackageSummaryPreview from '../../EditorPreview/PackageSummaryPreview';
import EditorSidebar from '../../EditorSidebar';
import PackageFooter from '../../PackageFooter';
import ConfigSummaryRow from './ConfigSummaryRow';
import ItemWithOptionsRow from './ItemWithOptionsRow';
import SummarySection from './SummarySection';

const Summary = () => {
  // Selectors
  const { editPackage } = useSelector(selectConfiguration);
  const { additionalPoses, additionalPoseFees, backgroundFees, imageOptionFees, subtotal } =
    useSelector(selectConfigurationSummary);
  const { isGreenScreen } = useSelector(selectGallery);
  const { backgroundSets, products } = useSelector(selectPriceSheet);
  const packageItemMap = useSelector(selectPackageItemMap);
  const { currency } = useSelector(selectAccount);

  // State
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Misc hooks
  const { goToCart, goToDetails, routeToWizardStep } = useConfigurationRouter();
  const { key, packageID } = useParams<Params>();
  const { getParamsValue } = useSearchParams();
  const cartPackageID = getParamsValue('cartPackageID');
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const extraPoses = intl.formatMessage({
    id: 'summary.extraPoses',
    defaultMessage: 'Extra Poses',
  });

  if (!editPackage) {
    return null;
  }

  const background = isGreenScreen ? getBackground(editPackage, backgroundSets) : null;
  const {
    name: packageName,
    price,
    additionalPoseFeeType,
  } = products[editPackage.priceSheetItemID] as ShopPackage;

  const handleAdd = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    if (cartPackageID) {
      const { valid } = await dispatch(updateCartItem(editPackage as CartPackage, key));
      if (valid) {
        goToCart();
      }
    } else {
      const { valid } = await dispatch(addCartItem(editPackage));
      if (valid) {
        goToDetails(products[packageID].categoryID);
      }
    }
    setIsSubmitting(false);
  };

  const handleBack = () => {
    routeToWizardStep('imageOption');
  };

  return (
    <>
      <Flex overflow="hidden" direction={{ base: 'column', md: 'row' }}>
        <PackageSummaryPreview />
        <EditorSidebar>
          <Flex direction="column" grow={1} padding={4} paddingBottom={10}>
            <Text fontSize="xl" fontWeight="bold" marginY={2} textAlign="center">
              {intl.formatMessage(
                {
                  id: 'summary.yourPackage',
                  defaultMessage: 'Your {packageName}',
                },
                { packageName },
              )}
            </Text>
            <Flex justify="center">
              <Text fontSize="2xl">{formatCurrency(subtotal, currency)}</Text>
            </Flex>
            {!!background && (
              <SummarySection
                heading={intl.formatMessage({
                  id: 'summary.background',
                  defaultMessage: 'Background',
                })}
              >
                <ConfigSummaryRow price={formatCurrency(backgroundFees, currency) || FREE}>
                  <>
                    <Swatch
                      position="relative"
                      size="30px"
                      url={`url(${background.sources.thumb}) no-repeat`}
                      cursor="auto"
                    />
                    <Text marginX={2}>{getNameWithoutExt(background.name)}</Text>
                  </>
                </ConfigSummaryRow>
              </SummarySection>
            )}
            <SummarySection
              heading={intl.formatMessage({
                id: 'summary.items',
                defaultMessage: 'Items',
              })}
            >
              <ConfigSummaryRow price={formatCurrency(price, currency)}>
                <Text>
                  {intl.formatMessage({
                    id: 'summary.basePrice',
                    defaultMessage: 'Base Price',
                  })}
                </Text>
              </ConfigSummaryRow>
              {editPackage.products.map(product => {
                const shopPkgItem = packageItemMap[product.priceSheetItemID];
                const price = getProductOptionFees(product, shopPkgItem);

                if (product.options?.length) {
                  return (
                    <ItemWithOptionsRow
                      key={product.id || product.priceSheetItemID}
                      editItem={product}
                      price={`${price > 0 ? formatCurrency(price, currency) : '$0'}`}
                      shopPkgItem={shopPkgItem}
                    />
                  );
                }

                return (
                  <ConfigSummaryRow
                    key={product.id || product.priceSheetItemID}
                    price={price > 0 ? formatCurrency(price, currency) : '$0'}
                  >
                    <Text>{packageItemMap[product.priceSheetItemID].name}</Text>
                  </ConfigSummaryRow>
                );
              })}
            </SummarySection>
            <SummarySection heading={ADDITIONAL_CART_ITEMS}>
              <ConfigSummaryRow price={formatCurrency(imageOptionFees, currency)}>
                <Text>{PHOTO_ADD_ONS}</Text>
              </ConfigSummaryRow>
              <AddOnsSummaryList fontSize="sm" />
              {!!additionalPoseFees && (
                <ConfigSummaryRow price={formatCurrency(additionalPoseFees, currency)}>
                  <Text>
                    {extraPoses} {additionalPoseFeeType === 'perImage' && `(x${additionalPoses})`}
                  </Text>
                </ConfigSummaryRow>
              )}
            </SummarySection>
            <Divider borderColor="grey.6" borderBottomWidth={3} marginY={3} />
            <Flex
              align="center"
              fontSize="lg"
              fontFamily="heading"
              paddingLeft={1}
              paddingRight={3}
            >
              <Text>{TOTAL}</Text>
              <Spacer />
              <Text data-test="total-value">{formatCurrency(subtotal, currency)}</Text>
            </Flex>
          </Flex>
        </EditorSidebar>
      </Flex>
      <PackageFooter
        loading={isSubmitting}
        nextLabel={cartPackageID ? UPDATE : ADD_TO_CART}
        onBack={handleBack}
        onNext={handleAdd}
      />
    </>
  );
};

export default Summary;
