import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { validateGalleryImageSupport } from '../../../redux/selectors/configurations.selectors';
import { selectCanSelectAndBuy } from '../../../redux/selectors/interactions.selectors';
import {
  selectAdditionalItems,
  selectPriceSheet,
} from '../../../redux/selectors/priceSheet.selectors';
import { resetConfiguration } from '../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { loadBackgroundImage } from '../../../redux/thunks/interactions.thunks';
import { Params } from '../../../shared/types/router';
import ProductCarousel from '../ProductCarousel';
import ProductsBreadCrumb from '../ProductsBreadCrumb';
import SelectAndBuyBanner from '../SelectAndBuyBanner';
import { PRODUCTS_GUTTER } from '../constants';
import ProductDetailsMain from './ProductDetailsMain';

const MAX_WIDTH = 1200;

const ProductDetails = () => {
  const { key, categoryID, productID } = useParams<Params>();
  const { categories, products, productCategoryMap } = useSelector(selectPriceSheet);
  const additionalItems = useAppSelector(s => selectAdditionalItems(s, products, productID!));
  const canSelectAndBuy = useSelector(selectCanSelectAndBuy);

  const product = products[productID!];
  const validGalleryProduct = useAppSelector(state => validateGalleryImageSupport(state, product));

  const detailsContainer = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, lg: false }, { ssr: false });

  useEffect(() => {
    // If the params, productID or categoryID, are not valid, route to Shop landing:
    if (!product || (categoryID && !categories[categoryID])) {
      history.push(`/${key}/shop/${categories[categoryID] ? categoryID : 'all'}`);
    }

    window.scrollTo(0, 0);
  }, [categories, categoryID, history, key, product]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes('/configure')) {
        dispatch(resetConfiguration());
        dispatch(loadBackgroundImage(null));
      }
    };
  }, [dispatch, history.location.pathname]);

  const sameCategoryProducts = productCategoryMap[product.categoryID].filter(
    productID => productID !== product.id,
  );

  return (
    <>
      {canSelectAndBuy[product.id] && <SelectAndBuyBanner />}
      <Flex align="center" direction="column" margin="auto" paddingX={`${PRODUCTS_GUTTER}px`}>
        <Flex direction="column">
          <ProductsBreadCrumb />
          <Flex
            maxWidth={`${MAX_WIDTH}px`}
            paddingBottom="70px"
            ref={detailsContainer}
            width="100%"
          >
            <ProductDetailsMain
              isMobile={isMobile}
              product={product}
              error={!validGalleryProduct}
            />
          </Flex>
        </Flex>
        <Box
          marginBottom="35px"
          maxWidth={`${MAX_WIDTH}px`}
          paddingBottom="100px"
          position="relative"
          width={`calc(100vw - ${PRODUCTS_GUTTER * 2}px)`}
        >
          {!!sameCategoryProducts.length && (
            <ProductCarousel
              data-test="Same Category Items-container"
              heading={intl.formatMessage({
                id: 'productDetails.sameCategory',
                defaultMessage: 'Same Category Items',
              })}
              items={sameCategoryProducts}
            />
          )}
          {!!additionalItems.length && (
            <ProductCarousel
              data-test="Additional Items You May Like-container"
              heading={intl.formatMessage({
                id: 'productDetails.additionalItems',
                defaultMessage: 'Additional Items You May Like',
              })}
              items={additionalItems}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};

export default ProductDetails;
