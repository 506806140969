import { Flex, Spacer, Text } from '@chakra-ui/react';
import { template } from 'iq-product-render';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CartTextNodeReq } from '../../../../../../../shop-api-client/models/Cart';
import { selectConfiguration } from '../../../../../../redux/selectors/configurations.selectors';
import { selectDynamicData } from '../../../../../../redux/selectors/gallery.selectors';
import { setEditNodeID } from '../../../../../../redux/slices/configurations.slice';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import FloatingLabelInput from '../../../../../../shared/components/FloatingLabelInput';
import { PLEASE_FILL_OUT_THIS_FIELD } from '../../../../../../shared/constants';
import { IS_TEMPLATE_STRING } from '../../../../../../shared/constants/regex.constants';
import TextNodeDisplay from '../TextNodeDisplay';

interface Props {
  autoFocus?: boolean;
  defaultText: string | null;
  canEdit: boolean;
  isInvalidProduct?: boolean;
  label: string;
  maxChars: number;
  node: CartTextNodeReq;
  onApply(node: CartTextNodeReq): void;
}

const TextNodeEntry = ({
  autoFocus,
  canEdit,
  defaultText,
  isInvalidProduct,
  label,
  maxChars = 255,
  node,
  onApply,
}: Props) => {
  const { editNodeID } = useAppSelector(selectConfiguration);
  const dynamicData = useSelector(selectDynamicData);
  const [focused, setFocused] = useState(autoFocus);
  const [isEditing, setIsEditing] = useState(false);
  const [originalText] = useState(node.text);
  const text = template(node.text || '', dynamicData);
  const [inputText, setInputText] = useState(text);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const isTemplateString = IS_TEMPLATE_STRING.test(defaultText || '');
  const defaultFieldName = template(defaultText || '', dynamicData);

  //#region useEffect
  useEffect(() => {
    // If the node is selected through the preview, focus the text input
    setFocused(canEdit && editNodeID === node.catalogNodeID);
  }, [canEdit, editNodeID, node]);

  /**
   * Ensures the input text is always the template text
   * As there's a race condition between generating the template text and setting into useState
   */
  useEffect(() => {
    setInputText(text);
  }, [text]);

  //#endregion useEffect

  const handleApply = () => {
    // Since this happens onBlur, deselect the edit node:
    dispatch(setEditNodeID(null));

    if (!isTemplateString) {
      return;
    }

    // If the default field name is the same as the input text, revert the node text back
    // to the default template string. This is necessary if, for example, the displayed value
    // is "First Name" from the converted template-string, and the user fires the `onChange` event
    // but undoes the change, in which case "First Name" should still show, but the saved value
    // should be "{{ subject.firstName }}"
    if (defaultFieldName === node.text) {
      const updated = { ...node, text: defaultText };
      onApply(updated);
    }
  };

  const handleCancel = () => {
    onApply({ ...node, text: originalText });
    setInputText(template(originalText, dynamicData));
    setIsEditing(false);
  };

  const handleEdit = () => {
    setFocused(true);
    if (inputText === defaultFieldName) {
      setInputText('');
    }
    setIsEditing(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > maxChars) {
      return;
    }
    onApply({ ...node, text: e.target.value });
    setInputText(e.target.value);
  };

  const handleFocus = () => {
    if (node.catalogNodeID !== editNodeID) {
      dispatch(setEditNodeID(node.catalogNodeID));
    }
  };

  const customerCollected = !defaultFieldName;

  return (
    <Flex direction="column" width="100%">
      <Flex data-test={`${label}-container`} direction="column" marginY={1}>
        {isEditing || customerCollected ? (
          <FloatingLabelInput
            autoFocus={focused}
            fontSize="md"
            invalidMessage={PLEASE_FILL_OUT_THIS_FIELD}
            isInvalid={isInvalidProduct && !node.text}
            isRequired
            marginTop={1}
            name="Text Node"
            onBlur={handleApply}
            onCancel={handleCancel}
            onChange={handleOnChange}
            onFocus={handleFocus}
            inputLabel={label}
            showCancel={!customerCollected}
            value={inputText}
            width="100%"
            data-test={`${label}-input`}
          />
        ) : (
          <TextNodeDisplay
            label={label}
            onEdit={handleEdit}
            marginTop={1}
            updated={text !== defaultFieldName}
            updatedText={inputText}
            width="100%"
          />
        )}
        <Flex>
          {(isTemplateString || !customerCollected) && (
            <Text
              data-test={`${label}-explainer-text`}
              color="grey.5"
              fontSize="16px"
              marginX="5px"
              marginTop="2px"
              fontFamily="Inter"
            >
              {intl.formatMessage({
                id: 'textNodeEntry.noValueProvided',
                defaultMessage:
                  'If no value is provided, the product will be printed using the data on file',
              })}
            </Text>
          )}
          <Spacer />
          <Text color="grey.5" fontSize="sm" paddingRight={1} textAlign="right" whiteSpace="nowrap">
            {maxChars > 0 &&
              intl.formatMessage(
                {
                  id: 'textNodeEntry.characterCount',
                  defaultMessage: '{usedCount} of {maxChars}',
                },
                { usedCount: inputText.length, maxChars },
              )}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TextNodeEntry;
