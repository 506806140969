const Checkbox = {
  defaultProps: {
    size: 'lg',
  },
  baseStyle: {
    control: {
      borderRadius: '3px',
      padding: '0',
      margin: '0px',
      color: 'white',
      borderColor: 'grey.3',
      borderWidth: '2px',
      _checked: {
        bg: 'brand',
        borderColor: 'brand',
        color: 'white',
        _hover: {
          bg: 'brand',
          borderColor: 'brand',
        },
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'focus',
      },
    },
    icon: {
      color: 'white',
      height: '100%',
      width: '100%',
    },
    label: {
      marginLeft: '15px',
      fontSize: '14px',
      color: 'textPrimary',
      fontFamily: 'Inter',
    },
  },
  sizes: {
    lg: { label: { fontSize: '16px' } },
  },
};
export default Checkbox;
